import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  Grid,
  Divider,
  InputAdornment,
  Avatar
} from "@material-ui/core";
import { colors, theme } from "../../../components/src/theme";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SearchIcon from '@material-ui/icons/Search';
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined';
import { deliveredTick, sendIcon } from "./assets"
import { CustomizedTextFieldAutoComplete } from "../../../components/src/CustomizedComponents.web";
import FooterWeb from "../../../components/src/Footer.web";
import { AllchatsData, Messages, MessagesData } from "./types";
import Navbar from "../../../components/src/Navbar";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Loader from "../../../components/src/Loader";
import moment from "moment";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
// Customizable Area End

import ChatController, { Props } from "./ChatController";



export default class Chat extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  MessageBox = (message: MessagesData) => {
    const isBusinessOwner = message.attributes.sender_type === "Business_Owner";
    const isCurrentUserMessage = (this.state.userType === 'PO' && !isBusinessOwner) || (this.state.userType === 'BO' && isBusinessOwner);
    return (
      <>
        <Grid key={message.id}
          container
          style={isCurrentUserMessage ? webStyles.messageContainerBusinessOwner as React.CSSProperties : webStyles.messageContainer as React.CSSProperties}
        >
          <Grid item xs={12} sm={6}
            style={isCurrentUserMessage ? webStyles.messageContainerBusinessOwner as React.CSSProperties : webStyles.messageContainer as React.CSSProperties}
          >
            {!isCurrentUserMessage && <Avatar
              src={this.state.previewMessages.attributes.profile_pic?.url}
              style={webStyles.messageProfile}
            />}
            <Box style={isCurrentUserMessage ? webStyles.businessOwnerMessage as React.CSSProperties : webStyles.petOwnerMessage as React.CSSProperties}>
              <img src={message.attributes.attachments && message.attributes.attachments[0].url} style={webStyles.uploadImage} />
              <Typography variant="body1" align={"left"}  >{message.attributes.message}</Typography>
              <Typography variant="caption" display="block" align={'right'}>
                {moment(message.attributes.created_at).format('LT')} &nbsp;
                {isCurrentUserMessage && <img src={deliveredTick} />}
              </Typography>
            </Box>

          </Grid>
        </Grid>

      </>
    );
  };



  // Customizable Area End

  render() {
    // Customizable Area Start
    const { userType, allChats, chatLoader } = this.state;


    return (
      <>
        {userType === "PO" && <Navbar navType={'fullNavbar'} activeLink={3} />}
        {userType === "BO" && <NavigationMenu active={4} navigation={this.props.navigation} id="" />}
        <div style={userType === "BO" ?

          webStyles.bgContainer as React.CSSProperties : webStyles.pOBgContainer as React.CSSProperties}>
          <Grid container direction="row" >
            <Grid item xs={12} style={{ padding: '1rem' }}>
              <Grid container direction="row" style={webStyles.messageLayout} >
                <Grid item xs={5}>
                  <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} style={webStyles.chatListLayout}>
                      <Box style={webStyles.searchOutline}>
                        <SearchIcon style={{ color: "#000", fontSize: "24px" }} />

                        <CustomizedTextFieldAutoComplete
                          placeholder="Search"
                          variant="outlined"
                          fullWidth
                          value={this.state.searchChatsName}
                          data-test-id="search_chats"
                          onChange={this.handleChangeSearchChats}
                        />
                      </Box>
                      <Box style={webStyles.chatListContainer as React.CSSProperties}>
                        {allChats && allChats.map((item: AllchatsData) => {
                          return (
                            <>
                              <Box
                                sx={webStyles.listItemContainer}
                                data-test-id="see_chat"
                                onClick={() => this.selectChat(item.id)}
                              >
                                <Box>
                                  <Box style={webStyles.profile_avtar}>
                                    <Avatar src={item?.attributes?.profile_pic?.url} style={{ height: "60px", width: "60px", border: "Solid 3px #326478" }} />
                                    <Box style={{ width: "100%" }}>
                                      <Box style={webStyles.profileContentWrapper}>
                                        <Box>
                                          <Typography variant="h5">{item.attributes.name}</Typography>
                                        </Box>
                                        <Box>

                                        <Typography style={{ color: "grey" }}>{item?.attributes?.last_message_and_duration?.duration}</Typography>
                                        </Box>
                                      </Box>
                                      <Box>
                                      <Typography variant="body1" style={webStyles.messagePreviewText as React.CSSProperties}>{item?.attributes?.last_message_and_duration?.last}</Typography>

                                      </Box>

                                    </Box>
                                  </Box>

                                </Box>


                              </Box>
                            </>
                          )

                        })}

                        {allChats && allChats.length === 0 &&
                          <Grid item xs={12}>
                            <Grid container direction="row" justifyContent="center" alignItems="center" style={{ height: '90%' }} >
                              <Grid item xs={12}>
                                <Typography align="center" variant="h6" style={webStyles.notFound}>No Users Found</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        }
                      </Box>
                    </Grid>
                  </Grid>

                </Grid>
                {this.state.chatId > 0 && <Grid item xs={7} style={{ position: 'relative' }}>
                  <Grid container direction="row">
                    <Grid item xs={12}>
                      <Grid container alignItems="center" justifyContent="space-between" style={{ padding: "7px 15px" }}>

                        <Box display={'flex'} alignItems={'center'}>
                          <Avatar src={this.state.previewMessages?.attributes?.profile_pic?.url} style={{ height: "32px", width: "32px" }} />
                          <Typography variant="body1" style={webStyles.backText}>
                            {this.state.previewMessages?.attributes?.name}
                          </Typography>
                        </Box>
                        {this.state.chatId > 0 &&
                          <Box position={'relative'} onClick={this.deleteChat}
                            data-test-id='delete_chat'
                            style={{ display: "none" }}
                          >
                            <DeleteOutlineIcon color="error" />

                          </Box>}
                      </Grid>

                      <Divider />
                    </Grid>
                    <Grid item xs={12} style={webStyles.chatMessages as React.CSSProperties} >
                      {this.state.previewMessages?.attributes?.messages?.map((message: Messages) => {
                        return (
                          <>

                            <Typography component={'div'} style={webStyles.durationText as React.CSSProperties}>{message?.duration}</Typography>
                            {message?.messages?.data.map((messageList: MessagesData, index: number) => {
                              return (
                                < React.Fragment key={index}>
                                  {this.MessageBox(messageList)}

                                </React.Fragment>
                              )
                            })}
                            {this.state.previewMessages?.attributes?.messages?.length === 0 &&
                              <>
                                <Grid container direction="row" justifyContent="center" alignItems="center" style={{ height: '90%' }} >
                                  <Grid item xs={12}>
                                    <Typography align="center" variant="h6" style={webStyles.notFound}>No Messages Found</Typography>
                                  </Grid>
                                </Grid>

                              </>
                            }
                            <div ref={this.messagesEndRef} />

                          </>
                        )

                      })}

                    </Grid>

                    <Grid item xs={12} style={webStyles.messageInput}>
                      <form onSubmit={this.sendMessage}>

                      <Grid container direction="row" spacing={1} alignItems="center">
                        <Grid item xs={10}>
                          <CustomizedTextFieldAutoComplete
                            placeholder="Start typing..."
                            variant="outlined"
                            onChange={this.handleEnterMessage}
                            value={this.state.sendMessageText}
                            fullWidth
                          />
                          {this.state.uploadImage&&
                          <Typography variant="subtitle1" style={webStyles.fileUploadText}>{`${this.state.uploadImage.name} image uploaded`}</Typography>
                          }
                          {this.state.imageError &&
                            <Typography variant="subtitle1" color="error">{this.state.imageError}</Typography>}
                        </Grid>
                        <Grid item xs={1} container justifyContent="center">
                          <label htmlFor="icon-button-file">
                            <input
                              type="file"
                              name="pdfPath"
                              id="icon-button-file"
                              onChange={this.handleUploadFile}
                              style={{ display: "none" }}
                              data-test-id="image_upload"
                            />
                            <PhotoLibraryOutlinedIcon style={{
                              cursor: 'pointer',
                              color: "#326478", fontSize: "26px", display: 'flex'
                            }}

                            />
                          </label>
                        </Grid>
                        <Grid item xs={1} container justifyContent="center">
                          <img src={sendIcon} width={20} height={20}

                            style={{ cursor: 'pointer' }}
                            data-test-id='send_message'
                            onClick={this.sendMessage}
                          />
                        </Grid>

                      </Grid>
                      </form>

                    </Grid>


                  </Grid>
                </Grid>}


              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FooterWeb footerNavigationData={this.state.socialLinksData} boAndPOLinks={this.state.poBOLinksData} />
            </Grid>
          </Grid>

        </div>
        <Loader loading={chatLoader} />

      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  bgContainer: {
    marginLeft: "140px", // Adjust based on the width of your sidebar
    flexGrow: 1,
    backgroundColor: theme.palette.primary.main,
    overflowY: "auto", // Allow main content area to scroll
  },
  pOBgContainer: {
    marginTop: "120px", // Adjust based on the width of your sidebar
    flexGrow: 1,
    backgroundColor: theme.palette.primary.main,
    overflowY: "auto", // Allow main content area to scroll
  },
  messageLayout: {
    backgroundColor: theme.palette.common.white,
    height: '100vh',
    maxWidth: '100%',
    boxShadow: " 0px 5.05px 25.23px 0px #00000026",
    borderRadius:'8px'
  },

  backText: {
    fontWeight: 700,
    color: colors.darkBlack,
    margin: '0 15px'
  },
  searchOutline: {
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    display: 'flex',
    alignItems: 'center',
    padding: '10px'
  },
  chatListLayout: {
    padding: '10px 40px',
    marginTop: '20px'
  },
  chatListContainer: {
    height: "calc(100vh - 160px)", // Adjust this calculation based on your header/footer height
    overflowY: "auto",
    margin: '15px 0px',
    overflowX: 'hidden'
  },
  threeDots: {
    cursor: 'pointer'
  },

  profileContentWrapper: { display: "flex",
   justifyContent: "space-between", 
   paddingLeft: "20px",
    alignItems: "top" },
  profile_avtar: {
    display: "flex",
    alignItems: "center",

  },

  container: {
    marginLeft: "140px", padding: "0px", backgroundColor: "#DCF0F8", width: "91%",

  },

  createButtonView: {
    display: "flex",
    justifyContent: "center",
    padding: "10px 0px",
    marginBottom: 20,
  },
  roomListContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  listItemContainer: {
    width: "100%",
    borderRadius: 10,
    margin: '20px 0px',
    cursor: 'pointer'

  },
  chatMessages: {
    height: 'calc(100vh - 125px)',
    overflowY: 'auto',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
  messageInput: {
    borderTop: '1px solid #CBD5E1',
    padding: '15px',
    width: '100%',
    marginRight: '20px'
  },

  // messages:
  businessOwnerMessage: {
    backgroundColor: '#54A7C9',
    color: '#ffffff',
    textAlign: 'right',
    padding: '8px',
    marginBottom: "8px",
    minWidth: '200px',
    maxWidth: '100%',
    borderRadius: '6px',
    overflowWrap: 'anywhere',


  },
  petOwnerMessage: {
    backgroundColor: '#F1F5F9',
    color: '#000000',
    textAlign: 'left',
    padding: '8px',
    marginBottom: '8px',
    minWidth: '200px',
    maxWidth: '100%',
    borderRadius: '6px',
    overflowWrap: 'anywhere',

  },
  messageContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  messageContainerBusinessOwner: {
    justifyContent: 'flex-end',
    display: 'flex'
  },
  messageProfile: {
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    marginRight: '10px'
  },
  uploadImage: {
    width: '100%',
    borderRadius: '5px'
  },
  durationText: {
    color: '#666663',
    fontSize: 12,
    fontWeight: 700,
    margin: '10px 0px',
    textAlign: 'center'
  },
  cursorPointer: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center'
  },
  notFound: {
    color: theme.palette.common.black,
    fontWeight: theme.typography.fontWeightBold
  },
  messagePreviewText: {
    fontWeight: theme.typography.fontWeightRegular,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: '50%',
    marginLeft:"20px"
  },
  fileUploadText:{
    color:theme.palette.success.main,
    fontWeight: theme.typography.fontWeightRegular,

  }

};
// Customizable Area End
