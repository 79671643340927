import React from "react";
// Customizable Area Start
import {
  Grid,
  Box,
  Card,
  Typography,
  Divider,
  ClickAwayListener,
  Button,
  TextField,
  Modal,
  Paper,
  InputAdornment, Checkbox, FormControlLabel, Avatar, Snackbar

} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import { Theme, createStyles, styled, withStyles } from "@material-ui/core/styles";
import { boxShadows, theme } from "../../../components/src/theme";
import { arrowDown, payments, plusIcon, threeDots, timeIcon } from "./assets";
import { location } from "../../landingpage/src/assets";
import Footer from "../../../components/src/Footer.web";
import Navbar from "../../../components/src/Navbar";
import CustomRating, { CustomizedTextFieldAutoComplete } from "../../../components/src/CustomizedComponents.web";
import { CustomizedCalenderTextField } from "../../../components/src/CustomizeCalender";
import DeleteBookReservationModal from "./DeleteBookReservationModal.web";
import CustomDate from "../../../components/src/CustomizeCalenderPo";
import { CustomRadio } from "../../catalogue/src/PetDetailsPage.web";
import { errorImage } from "../../../components/src/CommonFunctions";
import { withSnackbar } from "../../../components/src/Toaster.web";
import CustomizedSnackbars from "../../../components/src/CustomSnackbar.web";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { dummyImage } from "../../catalogue/src/assets";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CustomMenu from "../../../components/src/cutomEditMenu";
import Loader from "../../../components/src/Loader.web";
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import { Alert } from "@material-ui/lab";
import moment from "moment";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
export const configJSON = require("./config");
import StripePayments from "../../stripepayments/src/StripePayments.web";

import {PetDetailsData,SelectedHotel,
} from "./ShoppingCartOrdersController";
import DayCareServiceModal from "../../../components/src/DayCareService";
// Customizable Area End

import ShoppingCartOrdersController, {
  Props,
} from "./ShoppingCartOrdersController";

export default class ShoppingCartOrders extends ShoppingCartOrdersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderServicePets = (serviceIndex: number, service: { additional_service_id: number; }, serviceType: string, classes: { positionR: string | undefined; headingText: string | undefined; modalPopup: string | undefined; modalPopupContent: string | undefined; petListBox: string | undefined; headingBlack: string | undefined; addPetsText: string | undefined; }) => {
    return (
      <>
        <ClickAwayListener onClickAway={() => this.closeServicePet(serviceIndex)} data-test-id="clickAwayServiceListnerId">
          <Box className={classes.modalPopup}>
            <Box className={classes.modalPopupContent}>
              <Grid item xs={12}>
                <Grid container direction="row" spacing={3}>
                  <Grid item xs={12}>
                    <Grid container direction="row" spacing={2}>
                      {this.state.serviceDogList && this.state.serviceDogList.map((petDatils: PetDetailsData, dogIndex: number) => {
                        return (
                          <Grid item xs={12} className={classes.petListBox} key={petDatils?.id}>
                            <Grid container direction="row" spacing={4} justifyContent="space-between" alignItems="center">
                              <Grid item xs={1} >
                                <Checkbox
                                  color="default"
                                  style={{ padding: "0px" }}
                                  inputProps={{
                                    "aria-label": "checkbox with default color",
                                  }}
                                  checked={this.checkedService(petDatils.attributes.name, service.additional_service_id, serviceType)}
                                  onChange={(event) => this.callService(event, service.additional_service_id, petDatils.attributes.name, serviceType)}
                                  data-test-id={`serviceCheckboxId${service.additional_service_id}${petDatils.attributes.name}${serviceType}`}
                                />
                              </Grid>
                              <Grid
                                item xs={9}
                              >
                                <Grid container
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}>
                                  <Grid item>
                                    <Avatar src={petDatils?.attributes?.pet_photo} />
                                  </Grid>
                                  <Grid item>
                                    <Typography
                                      className={classes.headingText}
                                      variant="subtitle1"
                                      data-test-id={`dogNameID${dogIndex}${serviceIndex}`}
                                    >
                                      {petDatils?.attributes?.name}
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography
                                      className={classes.headingBlack}
                                      variant="subtitle2"
                                    >
                                      ({petDatils?.attributes?.pet_type})</Typography>
                                  </Grid></Grid>
                              </Grid>
                              </Grid></Grid>
                        )
                      })}
                      {this.state.serviceCatList && this.state.serviceCatList.map((petDatils: PetDetailsData, dogIndex: number) => {
                        return (
                          <Grid item xs={12} className={classes.petListBox} key={petDatils?.id}>
                            <Grid container direction="row" spacing={4} justifyContent="space-between" alignItems="center">
                              <Grid item xs={1} >
                                <Checkbox
                                  checked={this.checkedService(petDatils.attributes.name, service.additional_service_id, serviceType)}
                                  inputProps={{
                                    "aria-label": "checkbox with default color",
                                  }}
                                  data-test-id={`$serviceCheckboxId${serviceIndex}${dogIndex}`}
                                  color="default"
                                  style={{ padding: "0px" }}
                                  onChange={(event) => this.callService(event, service.additional_service_id, petDatils.attributes.name,serviceType)}
                                />
                              </Grid>
                              <Grid
                                item xs={9}
                              >
                                <Grid 
                                  container
                                  alignItems="center"
                                  direction="row"
                                  spacing={2}
                                >
                                  <Grid item>
                                    <Avatar src={petDatils?.attributes?.pet_photo} />
                                  </Grid>
                                  <Grid item>
                                    <Typography
                                      data-test-id={`dogNameID${dogIndex}`}
                                      className={classes.headingText}
                                      variant="subtitle1"
                                    >
                                      {petDatils?.attributes?.name}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item>
                                    <Typography
                                      variant="subtitle2"
                                      className={classes.headingBlack}
                                    >
                                      ({petDatils?.attributes?.pet_type})</Typography>
                                  </Grid> 
                                  </Grid>
                              </Grid>
                              </Grid></Grid>
                        )
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </ClickAwayListener>
      </>
    )
  }

  renderPetInformation = (petTypeAdded: string, roomIndex: number, bookings: number) => {
    const { classes }: any = this.props

    return (
      <>
        <ClickAwayListener onClickAway={() => this.closePetPopup(roomIndex, petTypeAdded)} data-test-id="clickAwayListnerId">
          <Box className={classes.modalPopup}>
            <Box className={classes.modalPopupContent}>
              <Grid item xs={12}>
                <Grid container direction="row" spacing={3}>
                  <Grid item xs={12}>
                    <Grid container direction="row" spacing={2}>
                      {this.getDogMap(petTypeAdded) ? this.state.dogmap.map((petDatils: PetDetailsData, dogIndex: number) => {
                        return (
                          <Grid item xs={12} className={classes.petListBox} key={petDatils?.id}>
                            <Grid container direction="row" spacing={4} justifyContent="space-between" alignItems="center">
                              <Grid item xs={1} >
                                <Checkbox
                                  inputProps={{
                                    "aria-label": "checkbox with default color",
                                  }}
                                  color="default"
                                  style={{ padding: "0px" }}
                                  onChange={(event) => this.adddogsRoomOnClick(event, petDatils.id, petDatils.attributes.name, roomIndex, bookings)}
                                  disabled={this.getDisabled(roomIndex, petDatils)}
                                  checked={this.getChecked(petDatils.id)}
                                  data-test-id={`${petTypeAdded}CheckboxId${roomIndex}${bookings}${dogIndex}`}
                                />
                              </Grid>
                              <Grid
                                item xs={9}
                              >
                                <Grid container
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}>
                                  <Grid
                                    item>

                                    <Avatar src={petDatils?.attributes?.pet_photo} />
                                  </Grid>
                                  <Grid
                                    item>

                                    <Typography
                                      className={classes.headingText}
                                      variant="subtitle1"
                                      data-test-id={`dogNameID${dogIndex}`}
                                    >
                                      {petDatils?.attributes?.name}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item>
                                    <Typography
                                      variant="subtitle2"
                                      className={classes.headingBlack}
                                    >
                                      ({petDatils?.attributes?.pet_type})</Typography>
                                  </Grid> </Grid>
                              </Grid>
                              <Grid
                                style={{ position: 'relative' }}
                                item
                                xs={2}
                                container justifyContent="flex-end" alignItems="center">
                                <img
                                  src={threeDots}
                                  onClick={(event) => this.openEditInfo(petDatils?.id, event)}
                                  data-test-id="open_edit_pet1"
                                  style={{ cursor: "pointer" }}
                                />

                                <CustomMenu
                                  anchorEl={this.state.editInfoStatus}
                                  onClose={this.closeEditInfo}
                                  onEdit={this.OnEditPet}
                                  onDelete={this.handleRemovePet}
                                  customProp="Hello, Custom Prop!"
                                />
                              </Grid> </Grid></Grid>
                        )
                      }) : null}

                      {this.getCatMap(petTypeAdded) ? this.state.catmap.map((petDatils: PetDetailsData, catIndex: number) => {
                        return (
                          <Grid item xs={12} className={classes.petListBox} key={petDatils?.id}>
                            <Grid container direction="row" spacing={4} justifyContent="space-between" alignItems="center">
                              <Grid item xs={1} >

                                <Checkbox
                                  inputProps={{
                                    "aria-label": "checkbox with default color",
                                  }}
                                  color="default"
                                  style={{ padding: "0px" }}
                                  onChange={(event) => this.addcatsRoomOnClick(event, petDatils.id, petDatils.attributes.name, roomIndex, bookings)}
                                  disabled={this.getDisabledCat(roomIndex, petDatils)}
                                  checked={this.state.petId.includes(petDatils.id)}
                                  data-test-id={`${petTypeAdded}CheckboxId${roomIndex}${bookings}${catIndex}`}
                                />
                              </Grid>
                              <Grid item xs={9}>
                                <Grid container direction="row" alignItems="center" spacing={2}>
                                  <Grid item>

                                    <Avatar
                                      src={petDatils?.attributes?.pet_photo}
                                    />
                                  </Grid>
                                  <Grid item>

                                    <Typography variant="subtitle1" className={classes.headingText} data-test-id={`catNameID${catIndex}`}>
                                      ({petDatils?.attributes?.name})
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography variant="subtitle2" className={classes.headingBlack}>
                                      ({petDatils?.attributes?.pet_type})
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={2}
                                style={{ position: 'relative' }}
                                container justifyContent="flex-end" alignItems="center">
                                <img src={threeDots}
                                  data-test-id="open_edit_pet"
                                  style={{ cursor: "pointer" }}
                                  onClick={(event) => this.openEditInfo(petDatils?.id, event)}
                                />

                                <CustomMenu
                                  anchorEl={this.state.editInfoStatus}
                                  onClose={this.closeEditInfo}
                                  onEdit={this.OnEditPet}
                                  onDelete={this.handleRemovePet}
                                  customProp="Hello, Custom Prop!"
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        )

                      }) : null}

                    </Grid>

                  </Grid>
                  <Grid item xs={12}>
                    <Grid container direction="row" alignItems="center" justifyContent="flex-end">

                      {/* <Grid item>
                        <Typography variant="body1" color="error" className={classes.petInfoText}>
                          You have to add 2 dogs and 1 cat detail as per your order cart
                        </Typography>
                      </Grid > */}
                      <Grid item style={{ cursor: "pointer",display:"flex",alignItems:"center" }}>
                        <img src={plusIcon} width={16} height={16} style={{marginRight: 6}} />
                        <Typography display="inline" align="center" variant="body1" color="secondary"
                          data-test-id="add_pets"
                          onClick={this.navigateAddPets}
                          className={classes.addPetsText} >
                          Add Pet Info
                        </Typography>
                      </Grid >
                    </Grid>
                  </Grid>
                  {Boolean(this.state.token) && <Grid item xs={12}>
                    <Typography variant="body1" align="center" className={classes.headingText}>
                      If you have already added pet profiles in your Account. You can go with
                      <Typography display="inline" variant="body1" className={classes.loginText} >
                        Sign in
                      </Typography>
                    </Typography>

                  </Grid>}
                </Grid>

              </Grid>
            </Box>
          </Box>
        </ClickAwayListener>
      </>
    )
  }

  renderServicesList = () => {
    const { classes }: any = this.props;
    const checkedServicesSelected = this.state.petType === 'dogs' ? this.state.checkedServicesDog : this.state.checkedServicesCat
    return (
      <>
        <ClickAwayListener onClickAway={this.addAdditionalService}>
          <Box className={classes.modalPopup} >
            <Box className={classes.modalPopupContent}>
              {/* <div style={{ marginBottom: "20px" }}>
                <Typography variant="h4" className={classes.headingBlack}>
                  Additional services for&nbsp;{this.state.petType}
                </Typography>
              </div> */}
              {this.state.additionalServicesPet.map((service, index) => (




                <Grid item xs={12} key={index}>
                  <Grid container alignItems="center" justifyContent="space-between"
                    style={{
                      padding: "2% 3%",
                      backgroundColor: checkedServicesSelected.includes(service.name) ? "#B2E4F8" : "#F0FAFE",
                      borderBottom: "1px solid #5D8899"
                    }}
                  >


                    <Grid item style={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        data-test-id={`checkBoxSelectServices_${index}`}
                        onChange={(event) => this.handleServiceCheckBox(event, this.state.petType, service.price, service.additional_service_id, service.id, service.service_provided_type === configJSON.daily)}
                        value={`${service.price} ${service.name}`}
                        // data-test-id="handleCheckboxChange"
                        inputProps={{
                          "aria-label": "checkbox with default color",
                        }}
                        checked={checkedServicesSelected.includes(service.name) || checkedServicesSelected.includes(service.name.split(' ')[0])}
                        color="default"
                        style={{ padding: "0px" }}
                      />
                      <Typography variant="body1" color="textSecondary">{service.name}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1" className={classes.headingText}>{service.price}</Typography>

                    </Grid>
                  </Grid>
                </Grid>
              ))}
              <div
                style={{
                  textAlign: "right",
                  margin: "5% 0px 0px",
                  cursor: "pointer",
                  textDecoration: "underline",
                  fontSize: "16px",
                  fontWeight: 700,
                  fontFamily: "inter",
                  color: "#EA0C78",
                }}
                onClick={this.addAdditionalService}
                data-test-id="selectRoomForPet"
              >
                Continue
              </div>
            </Box>
          </Box>
        </ClickAwayListener>
      </>
    )
  }
  renderRoomsList = (roomIndex: number) => {
    const { classes }: any = this.props


    return (
      <ClickAwayListener onClickAway={this.closeSelectRooms}>
        <Box className={classes.modalPopup}>
          <Box className={classes.modalPopupContent}>
            {this.bookingRooms(roomIndex)}

          </Box>
        </Box>
      </ClickAwayListener>
    )
  }




  renderAdditionalQuestions = () => {
    return (<>
      {this.getQuestionCondition() && <Grid item xs={12}>
        <Grid container direction="row" spacing={3}>
          {this.state.policyQuestions.additional_condition && <Grid item xs={12}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle2" >{this.state.policyQuestions.additional_condition}</Typography>
              </Grid>
            </Grid>
          </Grid>}
          {this.state.policyQuestions.additional_question_one && <Grid item xs={12}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle2" >{this.state.policyQuestions.additional_question_one}</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField disabled={this.state.orderConfirmatationStatus} variant="outlined" fullWidth data-test-id="question1" value={this.state.additionalQuestion1} onChange={(event) => this.handleQuestion1(event)} style={styles.questionText}/>
              </Grid>
            </Grid>
          </Grid>}
          {this.state.policyQuestions.additional_question_two && this.state.policyQuestions.additional_question_two !=="undefined" && <Grid item xs={12}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle2">{this.state.policyQuestions.additional_question_two}</Typography>
              </Grid>
              <Grid item xs={12}>
              <TextField disabled={this.state.orderConfirmatationStatus} variant="outlined" fullWidth data-test-id="question2" value={this.state.additionalQuestion2} onChange={(event) => this.handleQuestion2(event)} style={styles.questionText}/>
              </Grid>
            </Grid>
          </Grid>}
          {this.state.policyQuestions.additional_question_three && this.state.policyQuestions.additional_question_three !=="undefined" && <Grid item xs={12}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle2" >{this.state.policyQuestions.additional_question_three}</Typography>
              </Grid>
              <Grid item xs={12}>
              <TextField disabled={this.state.orderConfirmatationStatus} variant="outlined" fullWidth data-test-id="question3" value={this.state.additionalQuestion3} onChange={(event) => this.handleQuestion3(event)} style={styles.questionText}/>
              </Grid>
            </Grid>
          </Grid>}
        </Grid>
      </Grid>}
    </>
    )
  }

  renderCardPayments = () => {
    const { classes }: any = this.props;
    return (
      <>
        <Grid container direction="row" spacing={3} className={this.state.orderConfirmatationStatus && classes.cardLayout}>
          <Grid item xs={12}>
            <Grid container direction="row" alignItems="center" spacing={2}>
              <Grid item xs={12} sm={2}>
                <Typography variant="body1" className={classes.headingText}>Pay with</Typography>

              </Grid>
              <Grid item xs={12} sm={6}>
                <Divider className={classes.dividerRoot} />

              </Grid>
              <Grid item xs={12} sm={4}>
                <img src={payments} width={239} height={29} />
              </Grid>

            </Grid>

          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" className={classes.headingBlack}>
              We only accept Full Payment upfront.
            </Typography>
          </Grid>
          <Grid item xs={12} container alignItems="center" className={classes.cardNumberLayout}>
            <Typography variant="body2" className={classes.placeHolderTitle}>
              Card number
            </Typography>
            <CustomizedTextFieldAutoComplete
              placeholder="1234-5678-9012-3456"
              variant="outlined"
              className={classes.inputLocation}
              fullWidth
              InputProps={{ classes: { input: classes['input'] } }}
            />

          </Grid>
          <Grid item xs={12} style={{ marginTop: 15, marginBottom: 15 }}>
            <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={3}>
              <Grid item xs={12} sm={5} container alignItems="center" className={classes.cardNumberLayout}>
                <Typography variant="body2" className={classes.placeHolderTitle}>
                  Expiration
                </Typography>
                <CustomizedTextFieldAutoComplete
                  placeholder="MM/DD"
                  variant="outlined"
                  className={classes.inputLocation}
                  fullWidth
                  InputProps={{ classes: { input: classes['input'] } }}
                />
              </Grid>
              <Grid item xs={12} sm={2} />
              <Grid item xs={12} sm={5} container alignItems="center" className={classes.cardNumberLayout}>
                <Typography variant="body2" className={classes.placeHolderTitle}>
                  CVV
                </Typography>
                <Grid item xs={12}>
                  <CustomizedTextFieldAutoComplete
                    placeholder="3 digits"
                    variant="outlined"
                    className={classes.inputLocation}
                    fullWidth
                    InputProps={{ classes: { input: classes['input'] } }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} container alignItems="center" className={classes.cardNumberLayout}>
            <CustomizedTextFieldAutoComplete
              placeholder="Cardholder name"
              variant="outlined"
              fullWidth
              InputProps={{ classes: { input: classes['input'] } }}
            />
          </Grid>

        </Grid>
      </>
    )

  }

  serviceCalculations = (value: { pets: number, name: string; price: number; isDailyService: boolean; perDayPrice: number; isAllPets: boolean; additional_service_id: number }, totalDay: number, totalDayStay: number, classes: { priceSectionText: string | undefined; }) => {
    if(value.isDailyService && !value.isAllPets){
      return (<>
        <Typography
          variant="body2"
          color="textSecondary"
          className={classes.priceSectionText}
        >
          {`${value.name} X ${this.getTotalPets()} Pets X ${totalDay} Day`}{totalDayStay > 1 ? "s " : " "}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          className={classes.priceSectionText}
        >
          {`£${this.getTotalPetCost(value.perDayPrice, value.pets, totalDay)}`}
        </Typography>
      </>)
    } else if(!value.isDailyService && !value.isAllPets){
      return (<>
        <Typography
          variant="body2"
          color="textSecondary"
          className={classes.priceSectionText}
        >
          {`${value.name} X ${this.getTotalPets()} Pets`}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          className={classes.priceSectionText}
        >
          {`£${this.getPriceSectionText(value.price, value.pets)}`}
        </Typography>
      </>)
    } else if(value.isDailyService && value.isAllPets){
      return (<>
        <Typography
          variant="body2"
          color="textSecondary"
          className={classes.priceSectionText}
        >
          {`${value.name} X ${totalDay} Day`}{totalDayStay > 1 ? "s " : " "}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          className={classes.priceSectionText}
        >
          {`£${this.getTotalDaycareCost(value.perDayPrice, totalDay)}`}
        </Typography>
      </>)
    } else {
      return (<>
        <Typography
          variant="body2"
          color="textSecondary"
          className={classes.priceSectionText}
        >
          {`${value.name}`}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          className={classes.priceSectionText}
        >
          {`£${value.price}`}
        </Typography>
      </>)
    }
  }

  additionalServiceCalculation = (
    totalDay: number,
    totalDayStay: number,
    value: { name: string; price: number; isDailyService: boolean, perDayPrice: number, isAllPets: boolean, additional_service_id: number, pets: number },
    classes: { priceSectionText: string | undefined; }
  ) => {
    {
      if(value.isDailyService && value.isAllPets){
        return(
          <>
            <Typography
              variant="body2"
              color="textSecondary"
              className={classes.priceSectionText}
            >
              {`${value.name} X ${this.getTotalPets()} Pets X ${totalDay} Day`}{totalDayStay > 1 ? "s " : " "}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              className={classes.priceSectionText}
            >
              {`£${this.getTotalPetCost(value.perDayPrice, this.getTotalPets(), totalDay)}`}
            </Typography>
          </> 
        )
      } else if(!value.isDailyService && value.isAllPets) {
        return (
          <>
          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.priceSectionText}
          >
            {`${value.name} X ${this.getTotalPets()} Pets`}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.priceSectionText}
          >
            {`£${this.getPriceSectionText(value.price, this.getTotalPets())}`}
          </Typography>
        </> 
        )
      } else if (!value.isDailyService && !value.isAllPets){
        return (<>
          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.priceSectionText}
          >
            {`${value.name} X ${value.pets} Pets`}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.priceSectionText}
          >
            {`£${this.getPriceSectionText(value.price, value.pets)}`}
          </Typography>
        </>)
      } else {
        return ( <>
          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.priceSectionText}
          >
            {`${value.name} X ${value.pets} Pets X ${totalDay} Day`}{totalDayStay > 1 ? "s " : " "}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.priceSectionText}
          >
              {`£${this.getTotalPetCost(value.perDayPrice, value.pets, totalDay)}`}
          </Typography>
        </>);
      }
         
    }
  };
  
  ownServiceCalculation = (
    totalDay: number,
    totalDayStay: number,
    value: { name: string; price: number; isDailyService: boolean, perDayPrice: number, isAllPets: boolean, additional_service_id: number, pets: number },
    classes: { priceSectionText: string | undefined; }
  ) =>{
    if(value.isDailyService && value.isAllPets){
      return (<>
        <Typography
        color="textSecondary"
        variant="body2"
        className={classes.priceSectionText}
      >
        {`${value.name} X ${this.getTotalPets()} Pets X ${totalDay} Day`}{this.getDays(totalDayStay)}
      </Typography>
      <Typography
        variant="body2"
        className={classes.priceSectionText}
        color="textSecondary"
      >
        {`£${this.getTotalPetCost(value.perDayPrice, this.getTotalPets(), totalDay)}`}
      </Typography>
      </>)
    } else if(!value.isDailyService && !value.isAllPets) {
      return (
        <>
        <Typography
        variant="body2"
        className={classes.priceSectionText}
        color="textSecondary"
      >
        {`${value.name} X ${value.pets} Pets`}
      </Typography>
      <Typography
        variant="body2"
        className={classes.priceSectionText}
        color="textSecondary"
      >
        {`£${this.getPriceSectionText(value.price, value.pets)}`}
      </Typography>
        </>
      )
    } else if(!value.isDailyService && value.isAllPets) {
      return (   
      <>
        <Typography
        color="textSecondary"
        variant="body2"
        className={classes.priceSectionText}
      >
        {`${value.name} X ${this.getTotalPets()} Pets`}
      </Typography>
      <Typography
        variant="body2"
        className={classes.priceSectionText}
        color="textSecondary"
      >
        {`£${this.getPriceSectionText(value.price, this.getTotalPets())}`}
      </Typography>
        </> )
    } else {
      return (<>
        <Typography
          variant="body2"
          className={classes.priceSectionText}
          color="textSecondary"
        >
          {`${value.name} X  ${value.pets} Pets X ${totalDay} Day`}{this.getDays(totalDayStay)}
        </Typography>
        <Typography
          variant="body2"
          className={classes.priceSectionText}
          color="textSecondary"
        >
        {`£${this.getTotalPetCost(value.perDayPrice, value.pets, totalDay)}`}
        </Typography>
      </>)
    }
  };

  summaryDetails = () => {
    const { classes }: any = this.props;
    const {
      checkedServicesDogPrice,
      checkedServicesCatPrice,
      selectRoomForDog,
      selectRoomForCat,
      selectRoomForDogPrice,
      selectRoomForCatPrice,
      totalDay,
      priceTotal,
      addRoomDog,
      addRoomCat
    } = this.state;

    const totalDayStay = this.state.hotelType==="daycare_services"? this.state.dayCareDays: (Number(totalDay) || 0);

    return (
      <div>
        <div style={webStyle.sideRatingComptSecDiv}>
          {this.getSelectedHotel() && (
            this.state.dogSelectedHotel.map((selectedHotel: SelectedHotel)=> {
            return (<div style={webStyle.sideRatingComptSec}>
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.priceSectionText}
                >
                {`£${selectedHotel.price} ${selectedHotel.name} for dog X ${this.totalDay(totalDay)} Day`}{this.getDays(totalDayStay)}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.priceSectionText}
                >
                {`£${this.getTotal(selectedHotel.price, totalDayStay)}`}
              </Typography>
            </div>)})
          )}

          {this.getCatRoomPrice() && (
             this.state.catSelectedHotel.map((selectedHotel: SelectedHotel)=> (<div style={webStyle.sideRatingComptSec}>
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.priceSectionText}
              >
                {`£${selectedHotel.price} ${selectedHotel.name} for cat X ${this.totalDay(totalDay)} Day`}{this.getDays(totalDayStay)}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.priceSectionText}
              >
                {`£${this.getTotal(selectedHotel.price, totalDayStay)}`}
              </Typography>
            </div>)
            )
          )}
          {this.state.hotelType==="daycare_services" && <div style={webStyle.sideRatingComptSec}>
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.priceSectionText}
              >
                {`£${this.state.displayPrice} X ${this.state.petId.length} Pets `+this.getNumberOfWeeks()}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.priceSectionText}
              >
                {`£${this.calculatedayCarePrice()}`}
              </Typography>
            </div>
          }
          {this.state.checkedServicesCatPrice.map((value: {
            hotel_additional_service_id: number,
            isDailyService: boolean,
            name: string,
            perDayPrice: number,
            price: number,
            isAllPets: boolean,
            additional_service_id: number,
            pets: number
          }, serviceIndex: React.Key | undefined) => (
            <div style={webStyle.sideRatingComptSec} key={serviceIndex}>
              {this.additionalServiceCalculation(totalDayStay, totalDayStay, value, classes)}
            </div>
          ))}
          {this.state.ownServices.map((value: {
            hotel_additional_service_id: number,
            isDailyService: boolean,
            name: string,
            perDayPrice: number,
            price: number,
            isAllPets: boolean,
            additional_service_id: number,
            pets: number
          }, index: React.Key | undefined) => (
            <div style={webStyle.sideRatingComptSec} key={index}>
             {this.ownServiceCalculation(totalDayStay, totalDayStay, value, classes)}
            </div>
          ))}
          {this.state.transactionFee !== "" &&
            <div style={webStyle.sideRatingComptSec}>
            <Typography
                variant="body2"
                className={classes.priceSectionText}
                color="textSecondary"
              >
                {configJSON.transactionFeeText}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.priceSectionText}
              >
                {`£${this.state.transactionFee}`}
              </Typography>
            </div>
          }
        </div>

        <div style={webStyle.totalGrid}>
          <Typography
            variant="body1"
            className={classes.sideFirstText}
          >
            Total
          </Typography>
          <Typography
            variant="body1"
            className={classes.sideFirstText}
          >
            {`£${this.state.priceTotal}`}
          </Typography>
        </div>


      </div>
    );
  };

  renderAdditionalServices = (classes: { positionR: string | undefined; headingText: string | undefined; modalPopup: string | undefined; modalPopupContent: string | undefined; petListBox: string | undefined; headingBlack: string | undefined; addPetsText: string | undefined; }) => {
    return (
      <>
        <div
          style={{
            ...webStyle.additionalselect,
            ...styles.additionalService
          } as React.CSSProperties}
        >
          <Box
            style={styles.serviceContainer as React.CSSProperties}>
            <span style={{ ...webStyle.countrySpan } as React.CSSProperties}>{configJSON.addAdditionalServices}</span>
            <Box style={styles.serviceBox as React.CSSProperties}>
              {this.state.checkedServicesCatPrice.map((value: { isAllPets: boolean; name: string }) => (
                <>
                {value.isAllPets &&
                  <span
                    style={{ ...webStyle.countrySpan, ...styles.rightMargin } as React.CSSProperties}>{value.name}</span>
                }
                </>))} 
                {this.state.ownServices.map((value) => (
                <>
                {value.isAllPets &&
                  <span
                    style={{ ...webStyle.countrySpan, ...styles.rightMargin } as React.CSSProperties}>{value.name}</span>
                }
                </>))} 
                </Box>
          </Box>
        </div>
        <div style={styles.petServiceBox}>
          {this.state.checkedServicesCatPrice.map((value: { isAllPets?: boolean; name?: string; additional_service_id: number; }, serviceIndex: number) => (
            <>
              {!value.isAllPets &&
                <>
                  <CustomizedCalenderTextField
                    value={value.name}
                    variant="outlined"
                    InputProps={{
                      readOnly: true
                    }}
                    fullWidth
                    disabled
                    data-test-id="open_catRoom"
                  />
                  <Grid item xs={12} style={styles.petDropDown} >
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12}>
                      </Grid>
                      <Grid item xs={12} className={classes.positionR}>
                        <div
                          data-test-id={`serviceDropDown${value.additional_service_id}`}
                          style={this.getServicePetName(value.additional_service_id, "additionalService") !== "Select pets" ? styles.petPopUp: styles.errorPetPopUp}
                          onClick={() => this.openPetServicePopup(serviceIndex, "additionalService")}
                        >
                        <Typography
                          className={classes.headingText}
                          variant="body1"
                        >
                          {this.getServicePetName(value.additional_service_id, "additionalService")}
                        </Typography>
                          <KeyboardArrowDownIcon
                            style={webStyle.arrowBtn as React.CSSProperties}
                          />
                        </div>
                        {this.state.petServiceList[serviceIndex]
                          &&
                          this.renderServicePets(serviceIndex, value, "additionalService", classes)
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              }
            </>))
          }

          {this.state.ownServices.map((value, serviceIndex: number) => (
            <>
              {!value.isAllPets &&
                <>
                  <CustomizedCalenderTextField
                    variant="outlined"
                    fullWidth
                    value={value.name}
                    disabled
                    InputProps={{
                      readOnly: true
                    }}
                    data-test-id="open_catRoom"
                  />
                  <Grid item xs={12} style={styles.petDropDown} >
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12}>
                      </Grid>
                      <Grid item xs={12} className={classes.positionR}>
                        <div
                          data-test-id={`ownServiceDropDown${value.additional_service_id}`}
                          onClick={() => this.openPetServicePopup(serviceIndex, "ownService")}
                          style={this.getServicePetName(value.additional_service_id, "ownService") !== "Select pets" ? styles.petPopUp: styles.errorPetPopUp}
                        >
                        <Typography
                          variant="body1"
                          className={classes.headingText}
                        >
                          {this.getServicePetName(value.hotel_additional_service_id, "ownService")}
                        </Typography>
                          <KeyboardArrowDownIcon
                            style={webStyle.arrowBtn as React.CSSProperties}
                          />
                        </div>
                        {this.state.ownPetServiceList[serviceIndex]
                          &&
                          this.renderServicePets(serviceIndex, value, "ownService", classes)
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              }
            </>
          ))}
        </div>
        
        {this.state.catAdditionalServices &&
          this.renderServicesList()}
      </>
    )
  }

  renderPetData = () => {
    const { classes }: any = this.props;
    const selectedServicesCats = this.state.checkedServicesCat.map((service: string, index: number) => { return service })
    const selectedServicesDogs = this.state.checkedServicesDog.map((service) => { return service })



    return (
      this.state.hotelType === "daycare_services" ?
      <DayCareDiv>
      <Box className="repeatBtnBox">
                <Box className="heading">
                  {configJSON.dayCareText}
                  <Divider />
                </Box>
                <Button onClick={this.handleModal}>{this.state.buttonText}</Button>
                <Divider className="endDivider"/>
              </Box>
      </DayCareDiv>
      :(<>

        {this.state.addRoomDog > 0 ?
          <> 
          <Grid item xs={12}>
            <Grid container direction="row" spacing={1} alignItems="center">
              <Grid item xs={12} sm={2}>
                <Typography variant="body1" className={classes.subHeadingText}>Select for dogs</Typography>
              </Grid>
              <Grid item xs={12} sm={10}>
                <Divider className={classes.dividerRoot} />
              </Grid>
            </Grid>

          </Grid>
            <Grid item xs={12}>
              <Grid container direction="row" spacing={2}>

                <Grid item xs={12} >
                  <Typography variant="body1" className={classes.headingText}>
                    {configJSON.selectDogRoom}
                  </Typography>
                </Grid>
                {this.state.dogSelectedHotel.length> 0 && this.state.dogSelectedHotel.map((hotelRoom, roomIndex )=><Grid item xs={12} className={classes.positionR}>
                  <CustomizedCalenderTextField
                    variant="outlined"
                    fullWidth
                    value={hotelRoom.name}
                    
                    disabled
                    InputProps={{
                      readOnly: true
                    }}
                    data-test-id='open_dogRoom'
                  />
                  {this.addPetDropDown("dog", roomIndex, hotelRoom.bookings, classes)}

                </Grid>)}
              </Grid>
            </Grid> 
            </>
          : null}

        {this.state.addRoomCat > 0 ?
          <>
            <Grid item xs={12}>
              <Grid container direction="row" spacing={1} alignItems="center">
                <Grid item xs={12} sm={2}>
                  <Typography variant="body1" className={classes.subHeadingText}>Select for cats</Typography>

                </Grid>
                <Grid item xs={12} sm={10}>
                  <Divider className={classes.dividerRoot} />
                </Grid>
              </Grid>

            </Grid>
            <Grid item xs={12}>
              <Grid container direction="row" spacing={2}>
              <Grid item xs={12} >
                  <Typography variant="body1" className={classes.headingText}>
                    {configJSON.selectCatRoom}
                  </Typography>
                </Grid>
                
                {this.state.catSelectedHotel.length> 0 && this.state.catSelectedHotel.map((hotelRoom, roomIndex )=><Grid item xs={12} className={classes.positionR}>
                  <CustomizedCalenderTextField
                    variant="outlined"
                    fullWidth
                    value={hotelRoom.name}
                    disabled
                    InputProps={{
                      readOnly: true
                    }}
                    data-test-id="open_catRoom"
                  />
                  {this.addPetDropDown("cat", roomIndex, hotelRoom.bookings, classes)}

                </Grid>)}
              </Grid>

            </Grid>
          </>
          : null} </>
      ))
  }

  renderRoomTypes1 = () => {

    const { selectedFor, roomsDog, roomsCat, selectRoomForDog } = this.state;
    const roomData: any = selectedFor === "dog" ? roomsDog : roomsCat;
    const newRoomData1: any = [...roomData]

    return (


      (newRoomData1.map((type: any, index: any) => (
        <div
          key={index}
        >

          <FormControlLabel value={type}
            data-test-id="handlePetTypeChange2"
            style={{
              padding: "10px 30px 10px 17px",
              backgroundColor: selectRoomForDog === type.name ? "#B2E4F8" : "#fff",
              borderRadius: "13px 13px 0px 0px",
              marginRight: "0px",
             

            }}
            control={
              <CustomRadio
                data-test-id="custom_radio"
                onChange={() => this.handlePetTypeChange(type.name, selectedFor, type.data.price, type.data.id)}
                checked={selectRoomForDog === type.name}
              />}

            label={
              <span style={{
                fontSize: "16px",
                fontFamily: "inter",
                fontWeight: selectRoomForDog === type.name ? 700 : 400,
                color: "#224350",
              }} >{type.name} </span>
            } />
        </div>))));
  }


  renderRoomTypes2 = () => {

    const { selectedFor, roomsDog, roomsCat, selectRoomForCat } = this.state;

    



    const roomData: any = selectedFor === "dog" ? roomsDog : roomsCat;
    const newRoomData1: any = [...roomData]

    return (
      (newRoomData1.map((type: any, index: any) => (
        <div
          key={index}
        >

          <FormControlLabel value={type}
            data-test-id="handlePetTypeChange2"
            style={{
              padding: "10px 30px 10px 17px",
              backgroundColor: selectRoomForCat === type.name ? "#B2E4F8" : "#fff",
              borderTopRightRadius: "13px",
              borderRadius: "13px 13px 0px 0px",
              borderTopLeftRadius: "13px",

            }}
            control={
              <CustomRadio
                data-test-id="custom_radio1"
                onChange={() => this.handlePetTypeChange(type.name, selectedFor, type.data.price, type.data.id)}
                checked={selectRoomForCat === type.name}
              />}

            label={
              <span style={{
                fontSize: "16px",
                fontFamily: "inter",
                fontWeight: selectRoomForCat === type.name ? 700 : 400,
                color: "#224350",
              }} >{type.name} </span>
            } />
        </div>)))
    )

  }

   indexToWord = (index: number) => {
    const numberWords = [
      'one',
      'two',
      'three',
      'four',
      'five',
      'six',
      'seven',
      'eight',
      'nine',
      'ten'
    ];

    if (index >= 0 && index < numberWords.length) { return numberWords[index]; } else { return `${index + 1}th`; }
  };

  renderRoomDetails = (roomIndex: number) => {
    const { selectedFor, roomsDog, roomsCat, selectRoomPet } = this.state;

    const roomData = selectedFor === "dog" ? roomsDog : roomsCat;
    const newRoomData1 = [...roomData]
    const selectedRooms = newRoomData1.find((room) => room.name === selectRoomPet) || newRoomData1[0];
    if (!selectedRooms) { return null; }

    return (
      <div>
        {selectedRooms.data?.pet_room_prices?.map((value: any, index: number) => (

          <div
            style={styles.addPetDiv}
            key={index}
          >

              

            <div style={{ display: "flex", alignItems: "center" }}>
              <CustomRadio
                checked={
                  (selectedFor === "dog" && this.state.dogSelectedHotel[roomIndex].price == value) ||
                  (selectedFor === "catRoom" && this.state.catSelectedHotel[roomIndex].price == value)}
                  data-test-id="handlePetTypeChangee"
                  onChange={() => this.checkroomprice(value, selectedFor, index)}
              />

              <Typography
                style={styles.headingText}
              > {`The room price of ${this.indexToWord(index)} ${selectedFor} per day`}
              </Typography> </div>

            <Typography
              style={styles.valueText} >{`£${value}`}  </Typography>
          </div>))}
      </div> ) };


  bookingRooms = (roomIndex: number) => {
    const { selectedFor, roomsDog, roomsCat, selectRoomPet } = this.state;

    const roomData = selectedFor === "dog" ? roomsDog : roomsCat;
    const newRoomData1 = [...roomData]
    const selectedRooms = newRoomData1.find((room) => room.name === selectRoomPet) || newRoomData1[0];



    

    const indexToWord1 = (index: number) => {
      const numberWords = [
        'One', 'Two', 'Three', 'Four', 'Five',
        'Six', 'Seven', 'Eight', 'Nine', 'Ten'
      ];

      if (index >= 0 && index < numberWords.length) {
        return numberWords[index];
      } else {
        return `${index + 1}th`;
      }
    };

   

    const getPetTypeExplanation = () => {


      return (
        <Typography
          style={{
            fontSize: "14px",
            fontWeight: 400,
            fontFamily: "inter",
          }}
        >
          {selectedRooms?.data?.description}



        </Typography>
      )
    }




    const getExplain = () => {
      const roomPrice = selectedRooms?.data?.pet_room_prices.length

      return (
        <Typography
          style={{
            fontWeight: 700,
            fontFamily: "inter",
            fontSize: "14px",
          }}>
          {roomPrice > 0 ? `${indexToWord1(roomPrice)} ${selectedFor}s fit into the room` : `${indexToWord1(roomPrice)} ${selectedFor} fit into the room`}
        </Typography>)
    }
    return (
      <>
        <div style={{
          width: "600px",
          backgroundColor: "#fff",
          borderRadius: "15px",
          padding: "30px 0px 0px 50px",
        }} >
          <div style={{
            marginRight: "50px",
            marginBottom: "50px"
          }} >
            
            <div style={{
              // justifyContent: "space-between",
              display: "flex",
              marginRight: "0px",
              padding: "0px 0px 0px 2%",
              alignItems: "center",
              columnGap: "20px",
              marginLeft:"10px"
            }} >   {this.state.dogOpenRoomStatus ? this.renderRoomTypes1() : this.renderRoomTypes2()} </div>
            <div style={{ backgroundColor: "#B2E4F8", padding: "30px 40px", borderRadius: "13px 13px 13px 13px", }} >

              <div
                style={{ marginBottom: "5px" }}> {getPetTypeExplanation()}</div>
              <div>
                <div
                  style={{
                    margin: "10px 0px"
                  }}>{getExplain()}</div>
                <div> {this.renderRoomDetails(roomIndex)}</div>  </div></div>
            <div style={{
              margin: "5% 0px 0px",
              textAlign: "right",
              textDecoration: "underline",
              cursor: "pointer",
              fontWeight: 700,
              fontSize: "16px",
              color: "#EA0C78",
              fontFamily: "inter",
            }}
              data-test-id="selectRoomForPet"
              onClick={this.closeSelectRooms}
            >  Continue</div></div>
        </div> </>);
  };

  interHeader = () => {
    const { classes }: any = this.props;
    return (
      <>
        {this.state.fromReservation ?
          <Box style={{ display: "flex", paddingLeft: "53px" }}>
            <Button
              onClick={() => this.onReservations()}
            >
              <ArrowBackIosOutlinedIcon />
            </Button>
            <Typography style={{ fontWeight: 700, fontSize: "24px" }}>Edit the reservation and confirm</Typography>

          </Box>
          :
          <Typography variant="h4" style={{ paddingLeft: '5rem' }} className={classes.headingText}>Confirm and Pay</Typography>

        }
      </>
    )
  }
 
  buttonText = () => {

    if (this.state.orderConfirmatationStatus) {
      return "Pay now"
    } else {
      return "Request to Book"
    }


  }


  renderPaymntUI = () => {

    const { classes }: any = this.props;
    return (
      <>
        {this.renderAdditionalQuestions()}
        <Grid container justifyContent="flex-start" spacing={2}>
          {this.state.orderConfirmatationStatus ?
            <Grid item xs={12}>
              <StripePayments navigation={undefined} id={""}
                orderId={this.state.order_id}
                orderPrice={this.state.orderPrice.toString()}
                connectAccoutnID={this.state.connectAccoutnID}
                getPaymentStatus={this.getPaymentStatus}
                policyType={this.state.cancellationDetails}
                transactionFees={this.state.transactionFee}
                stripeTermsConditions={this.state.termsAndConditionsUrl}
              />
            </Grid> :
            <>
            <Grid item xs={12} >
              <Grid container justifyContent="flex-start" spacing={1} className={classes.padding20}>
                <Grid item style={{ marginRight: "10px" }}>
                  <img src={timeIcon} width={38} height={38} />
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body1" className={classes.headingText}>
                    Your reservation won’t be confirmed until the host accepts your request (within 24 hours).
                    You won’t be charged until then.
                  </Typography>
                </Grid>

              </Grid >

            </Grid>
          <Grid item xs={12}>
            <Divider className={classes.dividerRoot} />
          </Grid>
          <Grid item xs={12} className={classes.padding20}>

                {this.state.serviceKey === "standard" ? this.renderPolicy() : this.renderPolicy1()}
              </Grid>
              <Grid item xs={12} className={classes.padding20}>
                <Button fullWidth variant="contained" color="secondary"
                  className={classes.bookButton}
                  disabled={this.state.checkOutValidation}
                  onClick={this.checkValidation}
                  data-test-id="book_now"

                >Request to Book
                </Button>
              </Grid>
            </>
          }


        </Grid>
      </>
    )
  }


  renderPolicy = () => {
    return (
      <>
        {this.state.standardCancellation.length > 0 &&
          this.state.standardCancellation.map((value: any) => {
            return (
              <>
                <div
                  style={{ fontFamily: "inter", fontWeight: 400 }}
                  dangerouslySetInnerHTML={{
                    __html: value?.attributes?.description
                  }}
                />
              </>
            )

          })
        }
      </>
    )
  }

  renderPolicy1 = () => {
    return (
      <>
        <Typography>1. Until {this.state.tailoredCancellation.free_cancellation_days} days the pet owner can cancel their reservation free of charge.</Typography>
        <Typography>2. Until {this.state.tailoredCancellation.free_refund_days} days the pet owner amend their reservation free of charge.</Typography>
        <Typography>3. The pet owner will be charged {this.state.tailoredCancellation.pre_payment_percent}% as pre payment. </Typography>
        <Typography>4. We will keep 100% of the total booking amount if Pet Owner does not turn up </Typography>          

  


      </>
    )
  }

  renderModal = () => {
    return (
    <DayCareServiceModal 
        openModal={this.state.openModal}
        closeModal={this.handleModal}
        userPet={this.state.userPetList}
        addDogRoom={this.state.addRoomDog}
        addCatRoom={this.state.addRoomCat}
        selectedPriceList={this.state.priceList}
        data-test-id="dayCareModalId"
        handleAdditionalDetails={undefined}
        handleType={undefined}
        addRoom={undefined}
        checkPrice={undefined}
        addSelectedDays={undefined}
        getSelectFeild={this.getSelectFeildText}
        selectedDaysList={this.state.selectedDays}
        selectedPetId={this.state.petId}
        selectedPricing={this.state.selectedPriceType}
        openPetModal={false}
        additionalServiceDetails={this.state.additionalDetails}
        displayPriceValue={this.getDisplayPrice()}
        modalErrorText={""}
        handleSubmit={undefined} 
        handleModal={undefined} 
        closePetModal={()=>{}}
        isDisabled={true}
        checkIn={this.state.checkInDate}
        checkOut={this.state.checkOutDate}
        handleCheckOutDate={undefined}
        handleCheckInDate={undefined}
        dateErrorText={""}
    />
    );
  }

  addPetDropDown = (petTypeAdded: string, roomIndex: number, bookings: number, classes: { positionR: string; headingText: string; }) => {
    return (<>
      <Grid item xs={12} style={styles.petDropDown} >
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
          </Grid>
          <Grid item xs={12} className={classes.positionR}>
            <div
              style={this.isPetSelected(petTypeAdded, bookings, roomIndex)?styles.petPopUp: styles.errorPetPopUp}
              data-test-id={`petDropDown${petTypeAdded}Id${roomIndex}`}
              onClick={() => this.openPetPopup(roomIndex, petTypeAdded)}
            >
              <Typography
                variant="body1"
                className={classes.headingText}
              >
                {this.getDogSelectionText(petTypeAdded, bookings) && (
                  <span>
                    {this.getDogNames(bookings, roomIndex)}
                  </span>
                )}
                {this.getCatSelectionText(petTypeAdded, bookings) && (
                  <span>
                    {this.getCatNames(bookings, roomIndex)}
                  </span>
                )}
              </Typography>
              <KeyboardArrowDownIcon
                style={webStyle.arrowBtn as React.CSSProperties}
              />
            </div>
            {(this.getRenderPetInformation(petTypeAdded, roomIndex))
              &&
              this.renderPetInformation(petTypeAdded, roomIndex, bookings)
            }
          </Grid>
        </Grid>
      </Grid>
    </>);
  }


  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes }: any = this.props;

    const coverPhotoMain = this.state.hotelDetails?.images?.find((image: any) => image.is_cover_image);


    return (
      <>
        {this.renderModal()}
        <CustomizedSnackbars duration={5000} openToaster={this.state.flashLogin} message={"Number of pets selected cannot be more than bookings in the room."} toasterType={"error"} />
        {this.state.fromReservation === true ? <Navbar navType="fullNavbar" activeLink={6} /> : <Navbar navType="fullNavbar" />}

        <CustomizedSnackbars
          message={this.state.toasterMessage}
          toasterType={this.state.toasterType}
          openToaster={this.state.toasterStatus} duration={5000} />

        <CustomizedSnackbars
          message={"Please select the pets for room first"}
          toasterType={"error"}
          openToaster={this.state.dogSelectionError} 
          duration={5000} 
        />

        <CustomizedSnackbars
          message={"Please select atleast one pet for additional service."}
          toasterType={"error"}
          openToaster={this.state.petSelectionErr} 
          duration={5000} 
        />
        <CustomizedSnackbars
          message={this.state.updateOrderError}
          toasterType={"error"}
          openToaster={this.state.updateOrderErrorMsg} duration={5000} />

        <CustomizedSnackbars
          message={this.state.modalError}
          toasterType={"error"}
          openToaster={this.state.isModalError} duration={2000} 
        />
        <CustomizedSnackbars
          message={this.state.availabilityErrorMessage}
          toasterType={"error"}
          openToaster={this.state.availabilityErrorCheck} duration={5000} 
        />


       

         <CustomizedSnackbars duration={5000} openToaster={this.state.petError} message={`Please Select ${Number(this.state.addRoomCat)+Number(this.state.addRoomDog)} Pets`} toasterType={"error"} />
         <CustomizedSnackbars duration={5000} openToaster={this.state.petError} message={`Please Select ${Number(this.state.addRoomCat)+Number(this.state.addRoomDog)} Pets`} toasterType={"error"} />


        <Grid item xs={12} className={classes.containerLayout} style={{ marginTop: "115px", width: "100%" }} >
          <Grid container direction="row" justifyContent="space-between" className={classes.content} style={{ margin: "auto", width: "90%" }}>
            <Grid item xs={12} className={classes.headdingLayout}>
              {this.interHeader()}

            </Grid>

            <Grid item style={{ width: "100%" }}>
              <Grid container justifyContent="space-between" style={{ width: "97.5%" }} >
                <Grid item className={classes.cartLayout} >
                  <Grid container direction="row" spacing={4}>
                    <Grid item xs={12} sm={6}>
                      <Grid container direction="row" spacing={2}>
                        <Grid item xs={12} >
                          <Typography variant="body1" className={classes.headingText} data-test-id="checkinId">Check in</Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.positionR}>

                          <CustomCalendar>
                            <CustomDate
                              data-test-id="dogbirth1"
                              value={this.state.checkInDate}
                              onChange={this.handleCheckInDate}
                              isDisabled={!this.state.fromReservation}
                            />
                            <CalendarTodayIcon
                              style={{
                                position: "absolute",
                                top: "32%",
                                right: "5%",
                                color: "#334155",
                                zIndex: 2
                              }}
                            />
                          </CustomCalendar>


                        </Grid>

                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Grid container direction="row" spacing={2}>

                        <Grid item xs={12}>
                          <Typography variant="body1" className={classes.headingText}>Check out</Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.positionR}>

                          <CustomCalendar>
                            <CustomDate
                              data-test-id="dogbirthd1"
                              value={this.state.checkOutDate}
                              isDisabled={!this.state.fromReservation}
                              onChange={this.handleCheckOutDate}
                              />
                            <CalendarTodayIcon
                              style={{
                                position: "absolute",
                                top: "32%",
                                right: "5%",
                                color: "#334155",
                                zIndex: 2
                              }}
                              />
                          </CustomCalendar>



                        </Grid>
                 {this.state.checkOutValidation &&  <Typography style={{color:"red"}}>CheckOut date should be greater then CheckIn date</Typography>}
                      </Grid>
                    </Grid>
                    {this.renderPetData()}
                    <Grid container direction="row" spacing={1} alignItems="center">
                      <Grid item xs={12} sm={2}>
                        <Typography variant="body1" className={classes.subHeadingText}>{configJSON.additionalServices}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={10}>
                        <Divider className={classes.dividerRoot} />
                      </Grid>
                    </Grid>
                    {this.renderAdditionalServices(classes)}



                    {(!this.state.orderConfirmatationStatus && !this.state.fromReservation) && <Grid item xs={12}>
                      <Divider className={classes.dividerRoot} />
                    </Grid>}


                    <Grid item xs={12}>
                      <Grid container justifyContent="center" spacing={2}>
                        {(this.state.fromReservation === true) ?
                          <>
                            <Grid item xs={12}>
                              <Box style={webStyle.footerbuttons}>
                                <Button style={webStyle.backbuttonstyle as React.CSSProperties}
                                  data-test-id="footerbackbtn"
                                  onClick={() => this.onReservations()}
                                >
                                  <ArrowBackIosOutlinedIcon style={{ fontSize: "18px" }} />
                                  <span style={{ textDecorationLine: "underline", marginLeft: "10px" }}>back</span>
                                </Button>

                                <Button style={{...webStyle.savebuttonstyle,color:(this.state.checkOutValidation || this.state.checkoutDayCare)?"grey":"#EA0C78"} as React.CSSProperties}
                                  onClick={() => this.orderUpdationApi()}
                                  disabled={this.state.checkOutValidation || this.state.checkoutDayCare}
                                  data-test-id="save_update_order"
                                >
                                  <span style={{ textDecorationLine: "underline", marginRight: "10px" }}>Save</span>
                                  <ArrowForwardIosOutlinedIcon style={{ fontSize: "18px" }} />
                                </Button>
                              </Box>
                            </Grid>
                          </>
                          :
                          this.renderPaymntUI()
                        }
                      </Grid>
                    </Grid>
                  </Grid>
               
                </Grid>
                <Grid item className={classes.cardPosition}>
                  <Card style={webStyle.cardLayout}>

                    <img src={coverPhotoMain?.url || this.state.hotelDetails?.images?.[0]?.url} onError={errorImage} className={classes.media} data-test-id='error_image' />
                    <Grid item xs={12}>
                      <Grid container direction="row" spacing={1}>
                        <Grid item xs={12}>
                          <Typography variant="h6" style={webStyle.hotelName}>{this.state.hotelDetails?.name}</Typography>
                        </Grid>
                        <Grid item xs={12} style={{ display: "flex" }}>
                          <img src={location} width={24} height={24} alt="" />
                          <Typography variant="body1" color="textSecondary" style={webStyle.locationName}>{this.state.hotelDetails?.hotel_city}{","}&nbsp;{this.state.hotelDetails?.hotel_country_region}</Typography>
                        </Grid>
                        <Grid item xs={12} >
                          <Grid container style={{ display: "flex", justifyContent: "space-between" }}>
                            <Grid item xs={12} sm={6} >
                              <Typography variant="h6" style={webStyle.hotelName}>£{this.getCheckPriceValue() || 0}</Typography>
                              <Typography variant="body1" color="textSecondary" style={webStyle.locationName}>Starting from Per Day</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} style={{ maxWidth: "37%" }}>
                              <Grid container justifyContent="flex-end">
                                <Grid item xs={12} >
                                  <CustomRating rating={this.state.hotelDetails?.rating || 0} />
                                </Grid>
                                <Grid item xs={12}>
                                  <Grid container justifyContent="space-between" alignItems="center" style={{ maxWidth: "94%" }}>
                                    <Grid item style={{ marginLeft: "7px" }}>
                                      <Typography variant="body1" color="textSecondary" style={webStyle.locationName}>{this.state.hotelDetails?.rating || 0}</Typography>
                                    </Grid>
                                    <Grid item>
                                      <Typography variant="body1" color="textSecondary" style={webStyle.locationName}>{this.state.hotelDetails?.reviews_count} Reviews</Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Box sx={webStyle.hoteldescribe}>
                      <Box style={webStyle.calculationbox}>

                        {this.summaryDetails()}
                      </Box>

                    </Box>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '120px' }}>
            <Footer color={theme.palette.primary.main} footerNavigationData={this.state.socialLinksData} boAndPOLinks={this.state.poBOLinksData}/>
          </Grid>
        </Grid>
        <DeleteBookReservationModal
          open={this.state.dailogOpen}
          handleClose={this.closeModal}
          typeOfModel={this.state.popupType}
          bookContinue={this.closeModal}

        />
        <Loader loading={this.state.ShoppingcartLoader} />

      </>
      //Merge Engine End DefaultContainer
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  questionText: {
    border: "2px solid rgb(101, 201, 241)", 
    borderRadius: "4px"
  },
  petServiceBox: {
    padding: "15px 0 0 20px",
    width: "96%"
  },
  additonalServices: {
    marginTop: "3px"
  },
  petDropDown: {
    marginBottom: "20px"
  },
  serviceBox: { 
    display: "flex", 
    flexWrap: "wrap",
    flexDirection: "column",
    height: "auto"
  },
  serviceContainer: {
    display: "flex", 
    flexDirection: "column", 
    width: "100%"
  },
  addPetDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "10px 0px",
    padding: "11px 13px",
    borderRadius: "10px",
    backgroundColor: "#fff"
  },
  headingText: {
    fontWeight: 400,
    fontFamily: "inter",
    fontSize: "14px"
  },
  valueText: {
    fontWeight: 700,
    fontFamily: "inter",
    fontSize: "14px"
  },
  petPopUp: {
    backgroundColor: "#E0F4FC", 
    display: "flex", 
    alignItems: "center",
    height: "43px",
    borderRadius: "8px",
    border: "2px solid #65C9F1",
    cursor: "pointer",
    paddingLeft: "10px"
  },
  errorPetPopUp: {
    display: "flex",
    borderRadius: "8px",
    backgroundColor: "rgb(255, 189, 184)",  
    alignItems: "center",
    height: "43px",
    border: "3px solid red",
    cursor: "pointer",
    paddingLeft: "10px"
  },
  rightMargin: {
    marginRight:"5px"
  },
  additionalService: {
    position: "relative", 
    alignItems: "baseline"
  },
  labeltext: {
    color: "#0F172A",
    fontWeight: 700
  },
  tabText: {
    color: "#0F172A",
    fontWeight: 400
  }
  
};
const shoppingCartOrderStyles = (theme: Theme) =>
  createStyles({
    containerLayout: {
      background: theme.palette.primary.main,
      height: "fit-content",
      marginTop: 120
    },
    headdingLayout: {
      background: theme.palette.common.white,
      maxWidth: '100%',
      height: '92px',
      boxShadow: boxShadows.blueShadow,
      [theme.breakpoints.down('xs')]: {
        width: "100%",
      }
    },
    content: {
      padding: "0 30px",
      [theme.breakpoints.down('xs')]: {
        padding: "0 15px",
      }
    },
    headingText: {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.common.black
    },
    cartLayout: {
      background: theme.palette.common.white,
      maxWidth: '70%',
      // minHeight: '900px',
      height: 'fit-content',
      borderBottomLeftRadius: "8px",
      borderBottomRightRadius: "8px",
      padding: '0 5rem 5rem  !important',
      [theme.breakpoints.down('xs')]: {
        width: "100%",
        padding: '0 1rem !important',

      }
    },
    cardPosition: {
      position: 'relative',
      top: '-70px',
      width: "28%",
      [theme.breakpoints.down('xs')]: {
        top: '0px',
      },
      right: "10px"
    },
    dividerRoot: {
      backgroundColor: theme.palette.info.main
    },
    authHeading: {
      margin: 'auto',
      maxWidth: 470,
      width: "100%",
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.common.black

    },
    editInfoPopup: {
      position: "absolute",
      zIndex: 9,
      width: "154px",
      height: "fit-content",
      borderRadius: 8,
      backgroundColor: "#ffffff",
      boxShadow: boxShadows.whiteShadow,
      top: '100%'
    },
    editPopupContent: {
      padding: "1rem "
    },
    modalPopup: {
      // position: "absolute",
      zIndex: 9,
      width: "100%",
      minHeight: "170px",
      height: 'object-fit',
      borderRadius: 8,
      backgroundColor: "#ffffff",
      boxShadow: boxShadows.whiteShadow,
    },
    modalPopupContent: {
      padding: "2rem "
    },
    loginText: {
      textDecoration: "underline",
      color: theme.palette.common.black,
      fontWeight: theme.typography.fontWeightBold,
      marginLeft: 10
    },
    petInfoText: {
      fontWeight: theme.typography.fontWeightRegular
    },
    addPetsText: {
      fontWeight: theme.typography.fontWeightBold,textDecorationLine:"underline",
    },
    signInButton: {
      border: `2px solid ${theme.palette.secondary.main}`,
      textTransform: 'capitalize',
      width: 325,
      height: 56,
      borderRadius: 8,
      fontSize: 16,
      fontWeight: theme.typography.fontWeightBold,
      '&:hover': {
        border: `2px solid ${theme.palette.secondary.main}`,

      },
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    registerButton: {
      textTransform: 'capitalize',
      width: 325,
      height: 56,
      borderRadius: 8,
      fontSize: 16,
      color: theme.palette.common.white,
      fontWeight: theme.typography.fontWeightBold,
      '&:hover': {
        backgroundColor: theme.palette.secondary.main
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    bookButton: {
      textTransform: 'capitalize',
      height: 56,
      borderRadius: 8,
      fontSize: 16,
      color: theme.palette.common.white,
      fontWeight: theme.typography.fontWeightBold,
      '&:hover': {
        backgroundColor: theme.palette.secondary.main
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    positionR: {
      position: 'relative',
      marginTop: "-10px"
    },
    bookingText: {
      fontSize: 14,
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightRegular
    }, bookingHighLightText: {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
      fontSize: 14
    },
    padding20: {
      padding: '20px !important'
    },
    headingBlack: {
      color: theme.palette.common.black,
      fontWeight: theme.typography.fontWeightRegular
    },
    media: {
      height: 244,
      borderRadius: 8,
      maxWidth: "100%",
      width: '100%',
      margin: 'auto',
      objectFit: 'cover'
    },
    cardNumberLayout: {
      height: 72,
      border: `1px solid ${theme.palette.info.main}`,
      borderRadius: 8
    },
    placeHolderTitle: {
      marginLeft: 10,
      color: theme.palette.text.disabled,
      fontWeight: theme.typography.fontWeightRegular
    },
    'input': {
      '&::placeholder': {
        textOverflow: 'ellipsis !important',
        color: theme.palette.text.disabled,
        fontWeight: theme.typography.fontWeightRegular,
        opacity: 10,
        fontSize: 20
      }
    },
    cardLayout: {
      marginTop: 30,
      marginBottom: 30
    },
    petListBox: {
      width: 630,
      height: 72,
      padding: "4px, 10px, 4px, 10px",
      gap: 20,
      borderBottom: `4px solid ${theme.palette.primary.main}`,
      boxShadow: boxShadows.blueShadow,
      marginBottom: 10

    }

  })

const ShoppingCartComponent = withStyles(shoppingCartOrderStyles, { withTheme: true })(withSnackbar(ShoppingCartOrders));
export class Shopping extends ShoppingCartOrdersController {
  render() {
    return <ShoppingCartComponent {...this.props} />;
  }
}




const CustomCalendar = styled(Box)({

  "& .rmdp-container": {
    width: "100%",
    fontFamily:"inter"
  },
  "& .rmdp-input": {
    cursor: "pointer",
    position: "relative",
    zIndex: 22,
    backgroundColor: "#61b0cf26",
    height: "43px",
    width: "100%",
    borderRadius: "8px",
    border: "2px solid #65C9F1",
    fontWeight: 700,
    fontFamily:"inter"
  },
  "& .rmdp-week-day": {
    color: "#000",
    fontFamily:"inter"
  },
  "& .rmdp-header": {
    borderRadius: 8,
    backgroundColor: "#CCEDFB",
    display: "flex",
    fontWeight: 400,
    fontSize: "16px",
    alignItems: "center",
    justifyContent: "center",
    fontFamily:"inter"
  },
  "& .rmdp-day.rmdp-today span": {
    color: "#000",
    backgroundColor: "#fff",
    fontFamily:"inter"
  },

  "& .rmdp-day.rmdp-selected span:not(.highlight) ": {
    color: "#fff",
    backgroundColor: "#EA0C78",
  },

  "& .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover": {
    color: "#fff",
    backgroundCcolor: "#EA0C78",
    fontFamily:"inter"
  },

  "& .rmdp-right i": {
    // WebkitTransform: "rotate(45deg)",
    marginRight: "3px",
    fontFamily:"inter"
  },
  "& .rmdp-arrow": { borderColor: "#EA0C78" },
 
  "& .rmdp-arrow-container.disabled .rmdp-arrow, .rmdp-arrow-container.disabled:hover .rmdp-arrow": {
    borderColor: "#EA0C78"
  }

})

const DayCareDiv = styled(Box)({
  "& .repeatBtnBox": {
    display:"flex",
    flexDirection:"column",
    alignItems:"flex-start",
    height:"220px",
    justifyContent:"space-between",
    width:"670px",
    "& .MuiButton-root":{
      color: "#EA0C78",
      fontFamily: "Inter",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      textTransform: "none",
      textDecoration: "underline",
      padding:"0px"
    }
  },
  "& .heading": {
    display: "flex",
    alignItems: "center",
    color: "#0F172A",
    gap:"5px",
    padding:"10px 0px",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    width: "100%",
    "& .MuiDivider-root": {
      height: "1px",
      background: "#65C9F1",
      width:"538px"
    },
  },
  "& .endDivider":{
      width: "100%",
      height: "1px",
      background: "#65C9F1"  
  }
});
const webStyle = {
  arrowBtn: {
    position: "absolute",
    right: "4%",
    color: "#334155"
  },
  footerbuttons: {
    width: "104%",
    height: "44px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  backbuttonstyle: {
    textTransform: "none",
    fontSize: "18px",
    fontWeight: 700,
    color: "#74748B"
  },
  savebuttonstyle: {
    textTransform: "none",
    fontSize: "18px",
    fontWeight: 700,
    color: "#EA0C78"
  },
  additionalselect: {
    padding: "5px 10px",
    width: "92.55%",
    borderRadius: "8px",
    border: "2px solid #65C9F2",
    position: "relative",
    backgroundColor: "#E0F4FC",
    justifyContent: "space-between",
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    marginTop: "10px",
    marginLeft: "2.5%"
  },
  countrySpan: {
    color: "#000",
    fontSize: "14px",
    fontWeight: 700, fontFamily: "inter",
    textTransform: "none",
    marginTop: "7px"
  },
  cardLayout: {
    // height: 632,
    minWidth: "100%",
    width: '100%',
    borderRadius: 15,
    padding: "10px 20px"
  },

  hotelName: {
    color: theme.palette.common.black,
    fontWeight: theme.typography.fontWeightBold,
    minHeight: 28
  },
  locationName: {
    fontWeight: theme.typography.fontWeightRegular,
    minHeight: 24
  },
  calculationbox: {
    width: "92%",
    border: "1px solid #65C9F2",
    borderRadius: 8,
    backgroundColor: theme.palette.primary.main,
    padding: "20px"
  },
  hoteldescribe: {
    maxWidth: 522,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "spaca-between",
    // backgroundcolor: "yellow",
    gap: 20
  },
  location: {
    width: 336,
    height: 25,
    display: "flex"
  },
  font14400: {
    fontSize: 14,
    fontFamily: "FontFamily(Font(R.font.inter))",
    fontWeight: 400,
    marginRight: 10
  },
  font14700: {
    fontSize: 14,
    fontFamily: "FontFamily(Font(R.font.inter))",
    fontWeight: 700,
  },
  totalGrid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "20px",
    marginTop: "20px",
    fontFamily: "inter",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#0F172A"
  },
  sideRatingComptSecDiv: {
    fontFamily: "inter",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    paddingBottom: "30px",
    borderBottom: "2px solid #B2E4F8"
  },
  sideRatingComptSec: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "30px",

  },
};
// Customizable Area End
