import React from "react";
// Customizable Area Start

import {
  Button,
  Typography,
  Menu,
  MenuItem,
  Grid,
  Checkbox,
  withStyles,
  Dialog,
  DialogContent,
  Radio,
  LinearProgress,
  CheckboxProps,
  RadioProps,
  FormControl,
  Select,
  Divider,
  Box,
  ClickAwayListener,
  RadioGroup,
  FormControlLabel,
  styled
} from "@material-ui/core";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import ClearIcon from "@material-ui/icons/Clear";
import Textarea from "@material-ui/core/TextareaAutosize";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import SideNav from "../../../components/src/SideNavbar";
import Footer from "../../../components/src/Footer.web";
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { configJSON } from "./BOwnerDetailsController.web";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
// Customizable Area End

import BussinessOwnerDetailsThirdController, {
  Props,
} from "./BussinessOwnerDetailsThirdController.web";
import BussinessOwnerSideBar from "../../../components/src/SideBar";
import { theme } from "../../../components/src/theme";
import { CreateYourOwnService, HotelAdditionalService } from "../../dashboard/src/types";
// Customizable Area Start
interface CustomCheckboxProps extends CheckboxProps {
  "data-test-id": string;
}

const CustomCheckbox = withStyles({
  root: {
    "&$checked": {
      color: "#EA0C78",
    },
    padding: "0px",
  },
  checked: {},
})((props: CustomCheckboxProps) => <Checkbox color="default" {...props} />);

interface CustomRadioProps extends RadioProps {
  "data-test-id"?: string;
}

export const CustomRadio = withStyles(() => ({
  root: {
    "&$checked": {
      color: "red",
    },
    color: "default",
  },
  checked: {},
}))((props: CustomRadioProps) => (
  <Radio
    color="default"
    disableRipple
    checkedIcon={
      <span
        style={{
          display: "inline-block",
          backgroundColor: "white",
          height: 7,
          borderRadius: "50%",
          width: 7,
          border: "6px solid #EA0C78",
        }}
      />
    }
    icon={
      <span
        style={{
          border: "1px solid #224350",
          display: "inline-block",
          borderRadius: "50%",
          height: 16,
          width: 16,
        }}
      />
    }
    {...props}
  />
));

const Styles = {
  root: {
    borderBottom: "1px solid #E0F4FC",
    padding: "10px",
    cursor: "pointer",

    // color :"red",
    // marginTop:"4rem"
  },


};

export const StyledDialog = withStyles({
  root: {
    "& .MuiDialog-paperScrollPaper": {
      borderRadius: "15px",
    }
  }
})((props: any) => <Dialog {...props} />);

const CustomMenuItem = withStyles((theme) => ({
  root: {
    padding: '10px',
    borderBottom: '1px solid #B2E4F8',
    borderRadius: '5px',

    '&:hover': {
      backgroundColor: '#B2E4F8',
      borderRadius: '5px'
    },
  },
}))(MenuItem);

// Customizable Area End

export default class BussinessOwnerDetailsThird extends BussinessOwnerDetailsThirdController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  openDilogSaveDraft = () => {
    return (
      <>
        <StyledDialog
          open={this.state.dilogeOpen}
          onClose={this.handleDilogeClose}
          data-test-id="handleModelClose"
          maxWidth="lg"
        >
          <DialogContent
            style={{ width: "450px", padding: "30px", borderRadius: "15px" }}
          >
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <ClearIcon onClick={this.handleDilogeClose} />
            </div>
            <div style={{ padding: "5%", margin: "20px 0px" }}>
              <Typography
                style={stepFourStyle.modelHeadingTexts as React.CSSProperties}
              >
                Save as Draft?
              </Typography>
              <Typography
                style={stepFourStyle.modelBodyTexts as React.CSSProperties}
              >
                Draft will be saved for a period 1 month and then deleted from
                the system
              </Typography>
            </div>
            <div style={stepFourStyle.modelBtnDiv}>
              <Button
                style={stepFourStyle.discartBtns as React.CSSProperties}
                onClick={this.handleDilogeClose}
              >
                Discard
              </Button>
              <Button
                style={stepFourStyle.saveDraftsBtns as React.CSSProperties}
                onClick={this.saveDraftData}
                data-test-id="saveDraftData"
              >
                Save Draft
              </Button>
            </div>
          </DialogContent>
        </StyledDialog>

      </>
    );
  };

  modelRadioPet = () => {
    let petTypeSection;
    if (this.state.pet_type === "dog_cat_hotel") {
      petTypeSection = (
        <>
          <div style={{ display: "flex", alignItems: "center", width: "215px", justifyContent: "space-between", paddingRight: "24px" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "2%",
              }}
            >
              <CustomRadio
                checked={this.state.selectedPet === "Dog"}
                onChange={() => this.handleRadioChange("Dog")}
                data-test-id="handleRadioChangeDogs"
              />
              <Typography style={stepStyle.radioButoonText}>Dog</Typography>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <CustomRadio
                checked={this.state.selectedPet === "Cat"}
                onChange={() => this.handleRadioChange("Cat")}
                data-test-id="handleRadioChangeCats"
              />
              <Typography style={stepStyle.radioButoonText}>
                Cat
              </Typography>
            </div>
          </div>
        </>
      );
    } else if (this.state.pet_type === "dog_hotel") {
      petTypeSection = (
        <div style={{ display: "flex", alignItems: "center", width: "215px", justifyContent: "flex-end", paddingRight: "22px" }}>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "2%",
            }}
          >
            <CustomRadio
              checked={this.state.selectedPet === "Dog"}
              onChange={() => this.handleRadioChange("Dog")}
              data-test-id="handleRadioChangeDog"
            />
            <Typography style={stepStyle.radioButoonText}>
              Dog
            </Typography>
          </div>
        </div>
      );
    } else if (this.state.pet_type === "daycare_services") {
      petTypeSection = (
        <>
          <div style={{ display: "flex", alignItems: "center", width: "215px", justifyContent: "space-between", paddingRight: "24px" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "2%",
              }}
            >
              <CustomRadio
                checked={this.state.selectedPet === "Dog"}
                onChange={() => this.handleRadioChange("Dog")}
                data-test-id="handleRadioChangeDogs1"
              />
              <Typography style={stepStyle.radioButoonText}>Dog</Typography>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <CustomRadio
                checked={this.state.selectedPet === "Cat"}
                onChange={() => this.handleRadioChange("Cat")}
                data-test-id="handleRadioChangeCats1"
              />
              <Typography style={stepStyle.radioButoonText}>
                Cat
              </Typography>
            </div>
          </div>
        </>
      );
    } else {
      petTypeSection = (
        <>
          <div style={{ display: "flex", alignItems: "center", width: "215px", justifyContent: "flex-end", paddingRight: "22px" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <CustomRadio
                checked={this.state.selectedPet === "Cat"}
                onChange={() => this.handleRadioChange("Cat")}
                data-test-id="handleRadioChangeCat"
              />
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: 700,
                  color: "#334155",
                }}
              >
                Cat
              </Typography>
            </div>
          </div>
        </>
      );
    }

    return petTypeSection;
  }

  openModel = () => {
    return (
      <>
        <StyledDialog
          open={this.state.modelOpen}
          onClose={this.handleModelClose}
          data-test-id="handleModelClose"
          maxWidth="lg"
          style={{ borderRadius: "15px" }}
        >
          {this.state.removeModel ? (
            <DialogContent style={{ width: "700px", padding: "20px" }}>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <ClearIcon onClick={this.handleModelClose} />
              </div>
              <div>
                <Typography
                  style={stepStyle.removeHeadText as React.CSSProperties}
                >
                  Delete Room Type
                </Typography>
                <Typography style={stepStyle.removeText as React.CSSProperties}>
                  Are you sure you want to remove this room type?
                </Typography>
              </div>
              <div style={stepStyle.modelButtonDiv}>
                <Button
                  style={stepStyle.clodeModelBtn as React.CSSProperties}
                  onClick={this.handleModelClose}
                >
                  No, I don't
                </Button>
                <Button
                  style={stepStyle.remmoveRoomBtn as React.CSSProperties}
                  onClick={this.removeRoom}
                >
                  Yes, Sure
                </Button>
              </div>
            </DialogContent>
          ) : (

            <DialogContent style={{ width: "700px" }}>
              <div
                style={stepStyle.dilogDiv as React.CSSProperties}
              >
                <ClearIcon onClick={this.handleModelClose} />
              </div>
              <div style={{ marginTop: "-30px" }}>
                <Typography style={stepStyle.threeStepSelectLable as React.CSSProperties} data-test-id="roomTypeText">
                  Room type
                </Typography>

                <input
                  type="text"
                  placeholder="E.g. Single Room, Double Room, Standard, etc."
                  style={{
                    ...stepStyle.secondStepInput,
                    width: "99%",
                    boxShadow: "none",
                  } as React.CSSProperties}
                  onChange={(event) => this.roomSelector(event)}
                  value={this.state.roomType}
                  data-test-id="priceHandlerRoomType"
                />
                {this.state.roomType === "" && <Typography style={{ color: "red" }}> {this.state.errorMsgRoomType} </Typography>}
              </div>
              <div style={{ marginBottom: "20px" }}>
                <Typography style={stepStyle.threeStepSelectLable2 as React.CSSProperties}>
                  Description
                </Typography>
                <Textarea
                  style={stepStyle.secTextarea as React.CSSProperties}
                  placeholder="Add the room description"
                  value={this.state.descriptionRoom}
                  onChange={(e) =>
                    this.priceHandler(e, "descriptionRoom")
                  }
                  data-test-id="priceHandlerdescriptionRoom"
                />
                {this.state.descriptionRoom === "" && <Typography style={{ color: "red" }}> {this.state.errorMsgDescription} </Typography>}
              </div>
              <div style={{ marginBottom: "20px" }}>
                <div
                  style={stepStyle.secondStepInputDiv as React.CSSProperties}
                >
                  <Typography
                    style={{
                      ...stepStyle.threeStepSelectLable,
                      marginBottom: "3px",
                      fontWeight: 400,
                    } as React.CSSProperties}
                  >
                    Pet Type
                  </Typography>{" "}
                  {this.modelRadioPet()}


                </div>
                {(this.state.pet_type === "cat_hotel" && this.state.selectedPet === "Dog") && <Typography style={{ color: "red" }}> {this.state.errorMsgPetType} </Typography>}
              </div>
              <div style={{ marginBottom: "20px" } as React.CSSProperties}>
                <div
                  style={stepStyle.secondStepInputDiv as React.CSSProperties}
                >
                  <Typography
                    style={{
                      ...stepStyle.threeStepSelectLable,
                      margin: "0px",
                      fontWeight: 400,
                    } as React.CSSProperties}
                  >
                    How many rooms are at the property?
                  </Typography>
                  <div style={stepStyle.handleRemoveDiv as React.CSSProperties}>
                    <RemoveCircleOutlineIcon
                      style={{
                        ...stepStyle.handleonClickRemoveDiv,
                        color: "rgb(255 108 180)",
                      } as React.CSSProperties}
                      onClick={this.minusRoomOnClick}
                      data-test-id="minus_button_dog"
                    />
                    <p style={stepStyle.addroomText}>
                      {this.state.addRoom}
                    </p>
                    <AddCircleOutlineIcon
                      style={stepStyle.handleonClickAddDiv as React.CSSProperties}
                      onClick={this.addRoomOnClick}
                      data-test-id="add_pet_plus"
                    />
                  </div>



                </div>
                {this.state.addRoom < 1 && <Typography style={{ color: "red" }}> {this.state.errorMsgRoomCount} </Typography>}
              </div>
              <div style={{ marginBottom: "20px" } as React.CSSProperties}>
                <div
                  style={stepStyle.secondStepInputDiv as React.CSSProperties}
                >
                  <Typography
                    style={{
                      ...stepStyle.threeStepSelectLable,
                      margin: "0px",
                      fontWeight: 400,
                    } as React.CSSProperties}
                  >
                    Maximum  {this.state.selectedPet.toLowerCase() + "s"} in one room
                  </Typography>{" "}
                  <div style={stepStyle.handleRemoveDiv}>
                    <RemoveCircleOutlineIcon
                      style={{
                        ...stepStyle.handleonClickRemoveDiv,
                        color: "rgb(255 108 180)",
                      } as React.CSSProperties}
                      onClick={this.minusdogsRoomOnClick}
                      data-test-id="minus_button_dogs"
                    />
                    <p style={stepStyle.addroomText} data-test-id="petNumber">
                      {this.state.selectedPet === 'Dog' ?

                        this.state.addRoomDog : this.state.catRoomDog}
                    </p>
                    <AddCircleOutlineIcon
                      style={stepStyle.handleonClickAddDiv}
                      onClick={this.adddogsRoomOnClick}
                      data-test-id="add_pets_plus"
                    />
                  </div>
                </div>
                {this.state.selectedPet === 'Dog' ? (this.state.addRoomDog < 1) && <Typography style={{ color: "red" }}> {this.state.errorPetCount} </Typography> : (this.state.catRoomDog < 1) && <Typography style={{ color: "red" }}> {this.state.errorPetCount} </Typography>}
              </div>

              {this.state.roomPricesArray && this.state.roomPricesArray.length > 0 ? (
                <div style={{ marginBottom: "20px" }}>
                  <Typography style={stepStyle.threeStepSelectLable} data-test-id="roomPriceText">
                    Room Prices
                  </Typography>
                  {this.renderRoomPrices()}
                </div>
              ) : null}

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  margin: "5% 0px 3%",
                }}
              >
                <Button
                  style={{
                    width: "325px",
                    height: "50px",
                    backgroundColor: "#EA0C78",
                    borderRadius: "8px",
                    color: "#fff",
                    fontSize: "16px",
                    fontWeight: 700,
                    textTransform: "none",
                    fontFamily: "inter",
                  }}
                  onClick={this.saveDataModel}
                  data-test-id="saveDataModel"
                >
                  Save
                </Button>
              </div>
            </DialogContent>
          )}
        </StyledDialog>
      </>
    );
  };

  renderRoomPrices = () => {
    const { roomPricesArray, selectedPet } = this.state;
    return roomPricesArray.map((value, index) => (
      <>

        <div

          style={
            {
              ...stepStyle.secondStepInputDiv,
              padding: "0px 0px 0px 2%",
              width: "97.6%",
              marginBottom: "10px",
            } as React.CSSProperties
          }
          key={index}
        >
          <Typography
            style={{
              ...stepStyle.threeStepSelectLable,
              margin: "0px",
              fontWeight: 400,
            }}
          >
            The room price for {value.name} {value.name != "one" ? `${selectedPet.toLowerCase()}s` : `${selectedPet.toLowerCase()}`} per day
          </Typography>{" "}
          <input
            type="text"
            placeholder="£ 000.00"
            value={value.price !== null ? `£ ${value.price}` : ""}
            onChange={(event) => this.priceHandlers(event, value.name)}
            data-test-id="priceHandlersss"
            style={stepStyle.priceOfDogInput}
          />
        </div>
        {this.state.errorMsgModel && this.state.errorRoomPricesArray.length && this.state.errorRoomPricesArray[index] && this.state.errorRoomPricesArray[index].isError ? <Typography style={{ color: "red" }}>{this.state.errorMsgModel}</Typography> : null}
        {this.state.errorMsgRoomPrice && <Typography style={{ color: "red" }}>{this.state.errorMsgRoomPrice}</Typography>}
      </>
    ));
  };

  renderRoomPricesData = (
    roomPricesArray: any[],
    selectedPet: any,
    index: number
  ) => {
    return roomPricesArray.map((value, idx) => (
      <div
        style={
          {
            ...stepStyle.secondStepInputDiv,
            padding: "0px 0px 0px 2%",
            width: "97.6%",
            marginBottom: "20px",
          } as React.CSSProperties
        }
        key={idx}
      >

        <Typography
          style={{
            ...stepStyle.threeStepSelectLable,
            margin: "0px",
            fontWeight: 400,
          }}
        >
          The room price for {value.name} {value.name != "one" ? `${selectedPet.toLowerCase()}s` : `${selectedPet.toLowerCase()}`} per day

        </Typography>{" "}
        <input
          type="text"
          placeholder="£ 000.00"
          value={value.price !== null ? `£ ${value.price}` : ""}
          onChange={(event) => this.priceHandlersData(event, value.name, index)}
          data-test-id={`priceHandlers`}
          style={stepStyle.priceOfDogInput}
        />
      </div>
    ));
  };

  setPriceForDaycare = () => {
    return (
      <>
        <div style={stepStyle.firstStepForm}>
          {this.state.pet_type === "Dog" && (
            <div style={stepStyle.slectedPriceInputDiv}>
              <div style={stepStyle.firstStepCheckBoxDiv}>
                <CustomCheckbox
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    this.handleCheckBox(e, "Dog")
                  }
                  data-test-id="handleCheckBoxDog"
                  checked={this.state.serviceYouSelect.includes("Dog")}
                />
                <Typography
                  style={stepStyle.firstStepsLable as React.CSSProperties}
                >
                  Daycare price for Dog
                </Typography>
              </div>
              <div>
                {this.state.serviceYouSelect.includes("Dog") ? (
                  <input
                    type="text"
                    style={stepStyle.slectedPriceInput as React.CSSProperties}
                    value={
                      this.state.dogAdditionalPrice &&
                        this.state.dogAdditionalPrice !== undefined
                        ? `£${this.state.dogAdditionalPrice}/day`
                        : ""
                    }
                    placeholder="£00.00/day"
                    onChange={(e) => this.formInputChange(e, "Dog")}
                    data-test-id="formInputChangeDog"
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          )}

          {this.state.pet_type === "Cat" && (
            <div style={stepStyle.slectedPriceInputDiv}>
              <div style={stepStyle.firstStepCheckBoxDiv}>
                <CustomCheckbox
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    this.handleCheckBox(e, "Cat")
                  }
                  data-test-id="handleCheckBoxCat"
                  checked={this.state.serviceYouSelect?.includes("Cat")}
                />
                <Typography
                  style={stepStyle.firstStepsLable as React.CSSProperties}
                >
                  Daycare price for Cat
                </Typography>
              </div>
              <div>
                {this.state.serviceYouSelect?.includes("Cat") ? (
                  <input
                    type="text"
                    style={stepStyle.slectedPriceInput as React.CSSProperties}
                    value={
                      this.state.catAdditionalPrice &&
                        this.state.catAdditionalPrice !== undefined
                        ? `£${this.state.catAdditionalPrice}/day`
                        : ""
                    }
                    placeholder="£00.00/day"
                    onChange={(e) => this.formInputChange(e, "Cat")}
                    data-test-id="formInputChangeCat"
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
          {this.setPriceForDogAndCat()}

          {this.setPriceForDaycareSec()}
        </div>
      </>
    );
  };

  setPriceForDogAndCat = () => {
    return (
      <>
        {this.state.pet_type === "Dog & Cat" && (
          <>
            <div style={stepStyle.slectedPriceInputDiv}>
              <div
                style={stepStyle.firstStepCheckBoxDiv as React.CSSProperties}
              >
                <CustomCheckbox
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    this.handleCheckBox(e, "Dog")
                  }
                  data-test-id="handleCheckBoxDogSec"
                  checked={this.state.serviceYouSelect?.includes("Dog")}
                />
                <Typography
                  style={stepStyle.firstStepsLable as React.CSSProperties}
                >
                  Daycare price for Dog
                </Typography>
              </div>
              <div>
                {this.state.serviceYouSelect?.includes("Dog") ? (
                  <input
                    type="text"
                    style={stepStyle.slectedPriceInput as React.CSSProperties}
                    value={
                      this.state.dogAdditionalPrice &&
                        this.state.dogAdditionalPrice !== undefined
                        ? `£${this.state.dogAdditionalPrice}/day`
                        : ""
                    }
                    placeholder="£00.00/day"
                    onChange={(e) => this.formInputChange(e, "Dog")}
                    data-test-id="formInputChangeDogSec"
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
            <div style={stepStyle.slectedPriceInputDiv}>
              <div style={stepStyle.firstStepCheckBoxDiv}>
                <CustomCheckbox
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    this.handleCheckBox(event, "Cat")
                  }
                  data-test-id="handleCheckBoxCatSec"
                  checked={this.state.serviceYouSelect?.includes("Cat")}
                />
                <Typography
                  style={stepStyle.firstStepsLable as React.CSSProperties}
                >
                  Daycare price for Cat
                </Typography>
              </div>
              <div>
                {this.state.serviceYouSelect?.includes("Cat") ? (
                  <input
                    type="text"
                    style={stepStyle.slectedPriceInput}
                    value={
                      this.state.catAdditionalPrice &&
                        this.state.catAdditionalPrice !== undefined
                        ? `£${this.state.catAdditionalPrice}/day`
                        : ""
                    }
                    placeholder="£00.00/day"
                    onChange={(event) => this.formInputChange(event, "Cat")}
                    data-test-id="formInputChangeCatSec"
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </>
        )}
      </>
    )
  }

  setPriceForDaycareSec = () => {
    return (
      <>
      </>
    );
  };

  firstStep = () => {
    return (
      <>
        <Grid style={stepStyle.secondStepMainDIv}>
          <div style={stepStyle.PricesTextSpan}>
            <span style={stepStyle.fontHadingText as React.CSSProperties}>
              Rooms and prices
            </span>
            <br />
            <span style={stepStyle.firstStepTextSpan as React.CSSProperties}>
              You can change it anytime. Select all that apply
            </span>
          </div>
        </Grid>
        <Grid>
          <div style={stepStyle.addRoomDiv}>
            <Typography style={stepStyle.threeStepSelectLable}>
              Set up your rooms with prices*
            </Typography>
            <div
              onClick={this.handleClickModelOpen}
              data-test-id="handleClickModelOpen"
            >
              <Typography style={stepStyle.firstStepText}>
                Add Room Type
              </Typography>
            </div>
          </div>
          {this.setPriceForDaycare()}
        </Grid>
      </>
    );
  };
  roomDetailsData = () => {
    return (
      <>

        {this.state.roomDetail &&
          this.state.roomDetail.map((value, index) => (
            <div
              key={index}
              style={{
                borderRadius: "15px",
                border: "2px solid #E0F4FC",
                padding: "15px",
                marginBottom: "20px"
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                onClick={() => this.removeRoomModel(index)}
                data-test-id="removeRoom"
              >
                <ClearIcon />
              </div>
              <div style={{ marginBottom: "2%" }}>
                <Typography style={stepStyle.threeStepSelectLable}>
                  Room type
                </Typography>

                <input
                  type="text"
                  placeholder="Select Room Type"
                  style={{
                    ...stepStyle.secondStepInput,
                    width: "99%",
                    boxShadow: "none",
                  } as React.CSSProperties}
                  onChange={(event) => this.roomSelectorData(event, index)}
                  value={value.roomType}
                  data-test-id="roomSelectorData"
                />
              </div>
              {value.roomType === "" && <Typography style={{ color: "red" }}> {this.state.errorMsgRoomType} </Typography>}
              <div style={{ marginTop: "2%" }}>
                <Typography style={stepStyle.threeStepSelectLable}>
                  Description of the room
                </Typography>
                <Textarea
                  style={stepStyle.secTextarea as React.CSSProperties}
                  placeholder="You'll have a great time at this comfortable place to stay."
                  value={value.descriptionRoom}
                  onChange={(event) =>
                    this.priceHandlerData(event, "descriptionRoom", index)
                  }
                  data-test-id="priceHandlerDatadescriptionRoom"
                />
                {value.descriptionRoom === "" && <Typography style={{ color: "red" }}> {this.state.errorMsgDescription} </Typography>}
              </div>
              <div style={stepStyle.roomDetailsPetDiv}>

                <div style={{ ...stepStyle.secondStepInputDiv as React.CSSProperties, marginBottom: "15px" }}>
                  <Typography
                    style={{
                      ...stepStyle.threeStepSelectLable,
                      fontWeight: 400,
                      margin: "0px",
                    }}
                  >
                    The room for
                  </Typography>
                  <div style={{ display: "flex", alignItems: "center", }}>
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        marginRight: "2%",
                      } as React.CSSProperties}
                    >

                      <Typography style={stepStyle.radioButoonText}>{value.selectedPet}</Typography>
                    </div>
                  </div>
                </div>


              </div>
              <div style={{ marginBottom: "20px" } as React.CSSProperties}>
                <div
                  style={stepStyle.secondStepInputDiv as React.CSSProperties}
                >
                  <Typography
                    style={{
                      ...stepStyle.threeStepSelectLable,
                      margin: "0px",
                      fontWeight: 400,
                    } as React.CSSProperties}
                  >
                    How many rooms are at the properties?
                  </Typography>
                  <div style={stepStyle.properiesRemoveDiv as React.CSSProperties}>
                    <RemoveCircleOutlineIcon
                      style={{
                        ...stepStyle.propertiesDiv,
                        color: "rgb(255 108 180)",
                      } as React.CSSProperties}
                      onClick={() => this.minusMorePropertyRoom(index)}
                      data-test-id="minusMorePropertyRoom"
                    />
                    <p style={stepStyle.addroomText}>
                      {value.addRoom}
                    </p>
                    <AddCircleOutlineIcon
                      style={stepStyle.handleonClickAddDiv as React.CSSProperties}
                      onClick={() => this.addMorePropertyRoom(index)}
                      data-test-id="addMorePropertyRoom"
                    />
                  </div>
                </div>
                {value.addRoom < 1 && <Typography style={{ color: "red" }}> {this.state.errorMsgRoomCount} </Typography>}
              </div>

              {/* <Typography style={stepStyle.threeStepSelectLable}>
                How many {value.selectedPet == "Dog" ? "dog" : 'cat'}s fit in to the room?
              </Typography> */}
              <div style={{ marginBottom: "15px" }}>
                <div
                  style={stepStyle.secondStepInputDiv as React.CSSProperties}
                >

                  <Typography
                    style={{
                      ...stepStyle.threeStepSelectLable,
                      margin: "0px",
                      fontWeight: 400,
                    }}
                  >
                    Maximum {value.selectedPet}s in one room
                  </Typography>{" "}
                  <div style={stepStyle.handleRemoveDiv}>
                    <RemoveCircleOutlineIcon
                      style={{
                        ...stepStyle.handleonClickRemoveDiv,
                        color: "rgb(255 108 180)",
                      }}
                      onClick={() => this.minusdogsRoomOnClickData(index)}
                      data-test-id="minusdogsRoomOnClickData"
                    />
                    <p style={stepStyle.addroomText}>
                      {value.selectedPet === 'Dog' ?

                        value.addRoomDog : value.catRoomDog}
                    </p>
                    <AddCircleOutlineIcon
                      style={stepStyle.handleonClickAddDiv}
                      onClick={() => this.adddogsRoomOnClickData(index)}
                      data-test-id="adddogsRoomOnClickData"
                    />
                  </div>
                </div>
                {value.selectedPet === 'Dog' ? (this.state.addRoomDog < 1) && <Typography style={{ color: "red" }}> {this.state.errorPetCount} </Typography> : (value.catRoomDog < 1) && <Typography style={{ color: "red" }}> {this.state.errorPetCount} </Typography>}

              </div>
              {value.roomPricesArray && value.roomPricesArray.length > 0 && (
                <div style={{ marginBottom: "15px" }}>
                  <Typography style={stepStyle.threeStepSelectLable}>
                    Pet room prices
                  </Typography>
                  {this.renderRoomPricesData(
                    value.roomPricesArray,
                    value.selectedPet,
                    index
                  )}
                </div>
              )}
            </div>
          ))}
      </>
    );
  };
  secondStep = () => {
    return (
      <>
        <Grid style={stepStyle.secondStepMainDIv}>
          <div style={stepStyle.PricesTextSpan}>
            <span style={stepStyle.fontHadingText as React.CSSProperties}>
              Rooms and prices
            </span>
            <br />
            <span style={stepStyle.firstStepTextSpan as React.CSSProperties}>
              You can change it anytime
            </span>
          </div>
        </Grid>

        <Grid style={stepStyle.secondStepMainDIv}>
          <div style={{ ...stepStyle.addRoomDiv, border: "none" }}>
            <Typography style={stepStyle.threeStepSelectLable} data-test-id="setupRoomText">
              Setup your room prices*
            </Typography>
            <div
              onClick={this.handleClickModelOpen}
              data-test-id="handleClickModelOpendfh"
              style={styles.button}
            >
              <AddCircleOutlineRoundedIcon fontSize="small" color="secondary" />&nbsp;
              <Typography style={stepStyle.firstStepText}>
                Add Room Type
              </Typography>
            </div>
          </div>

          {this.roomDetailsData()}
          {
            this.state.roomDetail.length > 0 && <div
              onClick={this.handleClickModelOpen}
              style={styles.button1}
            >
              <AddCircleOutlineRoundedIcon fontSize="small" color="secondary" />&nbsp;
              <Typography style={stepStyle.firstStepText}>
                {configJSON.addRoomType}
              </Typography>
            </div>}
          {this.state.errorMsg ? <Typography style={{ color: 'red' }}>{this.state.errorMsg}</Typography> : null}
          <Typography variant="body1" color="error">{this.state.priceErrorMessage}</Typography>

          {this.setPriceForDaycare()}
        </Grid>
      </>
    );
  };
  thirdStep = () => {
    return (
      <>
        <Grid style={stepStyle.secondStepMainDIv}>
          <div style={stepStyle.PricesTextSpan}>
            <span style={stepStyle.fontHadingText as React.CSSProperties}>
              Set Up Your Check-in And Check-out Times
            </span>
            <br />
            <span style={stepStyle.firstStepTextSpan as React.CSSProperties}>
              You can customize your availablity settings to host when and how you want.
            </span>
          </div>
        </Grid>

        <Grid
          style={{ marginTop: "40px", padding: "0px 0px 5%" }}
        >
          <div>
            <Typography style={stepStyle.threeStepSelectLable} >
              Do you have a set check-in and check-out times ?
            </Typography>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  marginRight: "2%",
                } as React.CSSProperties}
              >
                <CustomRadio
                  data-test-id="handleRadioChangecheckIn_Out"
                  checked={this.state.checkIn_Out}
                  onChange={() => this.handleCheckIn_Out(true)}
                />
                <Typography
                  style={stepStyle.radioButoonText as React.CSSProperties}
                >
                  Yes
                </Typography>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <CustomRadio
                  data-test-id="handleRadioChangecheckIn_Out2"
                  checked={!this.state.checkIn_Out}
                  onChange={() => this.handleCheckIn_Out(false)}

                />
                <Typography style={stepStyle.radioButoonText}>No</Typography>
              </div>
            </div>
          </div>
          {
            this.state.checkIn_Out ? <>
              <div style={{ marginTop: "40px" }}>
                <Typography style={stepStyle.threeStepSelectLable}>
                  Check-in
                </Typography>
              </div>
              <Grid container spacing={2}>
                <Grid item xs={6}>



                  <Grid style={stepStyle.inputDiv as React.CSSProperties}>
                    <div style={stepStyle.divOfInput}>From</div>

                    <div
                      onClick={(event) =>
                        this.handleSelectClick(event, "checkInFrom")
                      }
                      data-test-id="handleSelectClick"
                      style={stepStyle.selectTime}
                    >

                      <span style={stepStyle.divOfInputText}>{this.state.checkInFrom}</span>
                      <ExpandMoreIcon style={stepStyle.expandMoreIcon} />
                      {this.state.anchorElInFrom && this.renderCheckInPoup('checkInFrom')}
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid style={stepStyle.inputDiv as React.CSSProperties}>
                    <div style={stepStyle.divOfInput}>Untill</div>

                    <div
                      onClick={(event) =>
                        this.handleSelectClick(event, "checkInUntil")
                      }
                      data-test-id="handleSelectCloseSec"
                      style={stepStyle.selectTime}
                    >

                      <span style={stepStyle.divOfInputText}>{this.state.checkInUtill}</span>
                      <ExpandMoreIcon style={stepStyle.expandMoreIcon} />
                      {this.state.anchorElInUntill && this.renderCheckInPoup('checkInUntil')}



                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <div style={{ marginTop: "40px" }}>
                <Typography style={stepStyle.threeStepSelectLable}>
                  Check-out
                </Typography>
              </div>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Grid style={stepStyle.inputDiv as React.CSSProperties}>
                    <div style={stepStyle.divOfInput}>From</div>

                    <div
                      onClick={(event) =>
                        this.handleSelectClick(event, "checkOutFrom")
                      }
                      data-test-id="handleSelectClickThird"
                      style={stepStyle.selectTime}
                    >
                      <span style={stepStyle.divOfInputText}>{this.state.checkOutFrom}</span>
                      <ExpandMoreIcon style={stepStyle.expandMoreIcon} />
                      {this.state.anchorElOutFrom && this.renderCheckInPoup("checkOutFrom")}

                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid style={stepStyle.inputDiv as React.CSSProperties}>
                    <div style={stepStyle.divOfInput}>Untill</div>

                    <div
                      onClick={(event) =>
                        this.handleSelectClick(event, "checkOutUntil")
                      }
                      data-test-id="handleSelectClickFour"
                      style={stepStyle.selectTime}
                    >
                      <span style={stepStyle.divOfInputText}>{this.state.checkOutUtill}</span>
                      <ExpandMoreIcon style={stepStyle.expandMoreIcon} />
                      {this.state.anchorElOutUntill &&
                        this.renderCheckInPoup("checkOutUntil")


                      }
                    </div>

                  </Grid>
                </Grid>
              </Grid>

            </> : null}
        </Grid>
        <div style={{ marginTop: "40px" }}>
          <Typography style={stepStyle.threeStepSelectLable} >
            Do you accept more than 30 nights stay?
          </Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                alignItems: "center",
                display: "flex",
                marginRight: "2%",
              } as React.CSSProperties}
            >
              <CustomRadio
                data-test-id={"handleRadioChangenightStay"}

                checked={this.state.nightStay}
                onChange={() => this.handleNightStay(true)}
              />
              <Typography
                style={stepStyle.radioButoonText as React.CSSProperties}
              >
                Yes
              </Typography>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <CustomRadio
                checked={!this.state.nightStay}
                onChange={() => this.handleNightStay(false)}
                data-test-id="handleRadioChangenightStay2"
              />
              <Typography style={stepStyle.radioButoonText}>No</Typography>
            </div>
          </div>
        </div>


        <Divider style={{ height: '2px', marginTop: '10%', backgroundColor: '#B2E4F8' }} />
      </>
    );
  };

  forthStep = () => {
    return (
      <>
        <Grid style={stepStyle.secondStepMainDIv}>
          <div style={stepStyle.PricesTextSpan}>
            <span style={stepStyle.fontHadingText as React.CSSProperties}>
              You can select as many as you need
            </span>
            <br />
            <span style={stepStyle.firstStepTextSpan as React.CSSProperties}>
              You can change it anytime. Select all that apply
            </span>
          </div>
        </Grid>
        <div style={stepStyle.mainContainerSecDiv}>
          <div>
            <Grid>
              <Typography style={stepStyle.threeStepSelectLable}>
                Do you provide additional services?
              </Typography>
              <div style={stepStyle.mainContainerSecDiv}>
                <div style={{ position: 'relative' }}>
                  <div
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={this.handleSelect}
                    data-test-id="select_additionalServices"
                    style={stepStyle.countrySelect}
                  >
                    <span style={stepStyle.additionalServiceText}>{"Select the services"}</span>
                    <ExpandMoreIcon style={stepStyle.expandMoreIconRight} />
                    {this.state.anchorEl &&
                      <ClickAwayListener onClickAway={this.handleClosePopup}>
                        <Box style={stepFourStyle.dropDown as React.CSSProperties}>
                          <Box>
                            {this.state.options.map((option, index) => (
                              <CustomMenuItem
                                style={stepFourStyle.dropDownText}
                                key={index}
                                data-test-id="select_service"
                                onClick={() => { this.handleSelectSevices(option) }}
                              >
                                {option.attributes && option.attributes.service_name}
                              </CustomMenuItem>


                            ))}
                          </Box>
                        </Box>
                      </ClickAwayListener>
                    }


                  </div>

                </div>
                {this.state.errorMsg && <span style={stepStyle.errorServicePrice}>{this.state.errorMsg}</span>}
              </div>
              {this.renderAdditionalServices()}
            </Grid>
          </div>
        </div>
      </>
    );
  };

  renderAdditionalServices = () => {

    return (
      <>
        {(this.state.additionalServices.length > 0 || this.state.ownServicesList.length > 0) && (
          <div style={{ marginTop: "5%" }}>
            <div style={{ display: "flex" }}>
              <div style={styles.headingWidth2}>
                <Typography style={stepStyle.threeStepInputTextLeft}>
                  CHOSEN SERVICES
                </Typography>
              </div>
              <div style={styles.headingWidth1}>
                <Typography style={stepStyle.threeStepInputTextLeft}>
                  {configJSON.frequency}
                </Typography>
              </div>
              <div style={styles.headingWidth3}>
                <Typography style={stepStyle.threeStepInputTextLeft}>
                  {configJSON.serviceType}
                </Typography>
              </div>
              <div style={{ width: "20%" }}>
                <Typography style={stepStyle.threeStepInputText}>
                  ENTER YOUR PRICE
                </Typography>
              </div>
              <div style={styles.headingWidth4} />
            </div>
            <div>
              {this.additionalServices()}
              {this.ownServices()}
            </div>
          </div>
        )}

      </>

    )
  }

  additionalServices = () => {
    return (
      <>
        {this.state.additionalServices.map((service: HotelAdditionalService, serviceIndex) => {
          const { name } = service;
          const keywords = ['create', 'specify'];
          return (
            <div key={service.id}>
              <div style={{ display: "flex", marginTop: "15px" }}>
                <div style={{ width: "45%" }}>
                  <div style={stepStyle.servicesInput}>
                    {service.name}
                  </div>
                </div>


                <div style={styles.typBox}>
                  <div>
                    <div key={service.id} >
                      <RadioGroup
                        style={{ display: "flex !important", flexDirection: "row" }}
                        onChange={(event) => this.handleServiceType(event.target.value, service.id)}
                        value={service.service_provided_type || "OneTime"}
                        data-test-id="additional_service_type"
                      >
                        <FormControlLabel
                          value="OneTime"
                          
                          style={{
                            borderRadius: '8px',
                            width: '100%',
                            height: '33px',
                          }}
                          control={<Radio />}
                          label="One Time"
                        />
                        <FormControlLabel
                          label="Daily"
                          control={<Radio />}
                          value="Daily"
                          style={{
                            borderRadius: '8px',
                            width: '90%',
                            height: '33px',
                          }}
                        />
                      </RadioGroup>
                    </div>
                  </div>

                  <div>
                    <div key={service.id} >
                      <RadioGroup
                        data-test-id="additional_chargeType"
                        value={service.charge_type || "AllPets"}
                        style={styles.radioStyle}
                        onChange={(event) => this.handleChargeType(event.target.value, service.id)}
                      >
                         <FormControlLabel
                          style={styles.radioBtn}
                          value="AllPets"
                          control={<Radio />}
                          label="All pets"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          value="PerPet"
                          style={styles.radioBtn}
                          label="Per pet"
                          data-test-id="mrvalue"
                        />
                       
                      </RadioGroup>
                    </div>
                  </div>
                </div>




                <div style={{ width: "20%" }}>



                  <input
                    type="text"
                    placeholder="£00.00"
                    data-test-id="additional_service_price"
                    value={"£ " + service.price}
                    onChange={(event) =>
                      this.handlePriceChange1(service.id, event)}
                    style={service.id === 9 || keywords.some(keyword => name?.toLowerCase().includes(keyword)) ? stepStyle.activeThirdStepPriceInput : stepStyle.servicesDiv}

                  />


                </div>
                <div
                  style={{
                    width: "10%",
                    marginLeft: '20px',
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <button
                    onClick={() => this.handleDeleteService(service.id, false)}
                    data-test-id="delete_additional_service"
                    style={service.id === 9 || keywords.some(keyword => name?.toLowerCase().includes(keyword)) ? stepStyle.clearDivactive : stepStyle.clearDiv}
                  >
                    <ClearIcon />
                  </button>
                </div>
              </div>
              <Grid item xs={12}>
                {this.state.additionalInputErrors[`price_${service.id}`] && (

                  <Typography variant="body1" color="error">{this.state.additionalInputErrors[`price_${service.id}`]}</Typography>
                )}

              </Grid>
            </div>
          )
        })}
      </>
    )

  }
  ownServices = () => {
    return (
      <>
        {this.state.ownServicesList.map((service: CreateYourOwnService, serviceIndex: number) => {
          return (
            <div key={service.id}>
              <div style={{ display: "flex", marginTop: "15px" }}>
                <div style={{ width: "45%" }}>
                  <input
                    data-test-id='change_service'
                    type="text"
                    style={stepStyle.activeInput}
                    placeholder="Create Your Own Service"
                    value={service.name}
                    onChange={(event) => this.handleServiceNameChange(service.id, event.target.value)}
                  />
                </div>


                <div style={styles.typBox}>
                  <div>
                    <div key={service.id} >
                      <RadioGroup
                        value={service.service_provided_type || "OneTime"}
                        onChange={(event) => this.handleServiceType(event.target.value, service.id)}
                        style={{ display: "flex !important", flexDirection: "row" }}
                        data-test-id="service_type"
                      >
                        <FormControlLabel
                          value="OneTime"
                          control={<Radio />}
                          label="One Time"
                          style={{
                            borderRadius: '8px',
                            width: '100%',
                            height: '33px',
                          }}
                          data-test-id="mrvalue"
                        />
                        <FormControlLabel
                          value="Daily"
                          control={<Radio />}
                          label="Daily"
                          style={{
                            borderRadius: '8px',
                            width: '90%',
                            height: '33px',
                          }}
                          data-test-id="missvalue"
                        />
                      </RadioGroup>
                    </div>
                  </div>

                  <div>
                    <div key={service.id} >
                      <RadioGroup
                        value={service.charge_type || ""}
                        onChange={(event) => this.handleChargeType(event.target.value, service.id)}
                        style={styles.radioStyle}
                        data-test-id="ownService_chargeType"
                      >
                        <FormControlLabel
                          value="PerPet"
                          control={<Radio />}
                          label="Per pet"
                          style={styles.radioBtn}
                          data-test-id="mrvalue"
                        />
                        <FormControlLabel
                          value="AllPets"
                          control={<Radio />}
                          label="All pets"
                          style={styles.radioBtn}
                          data-test-id="missvalue"
                        />
                      </RadioGroup>
                    </div>
                  </div>
                </div>
                <div style={{ width: "20%" }}>
                  <input
                    type="text"
                    placeholder="£00.00"
                    data-test-id="special_service_price"
                    value={"£ " + service.price}
                    onChange={(event) =>
                      this.handlePriceChange1(service.id, event)
                    }
                    style={stepStyle.activeThirdStepPriceInput}

                  />


                </div>
                <div
                  style={{
                    width: "10%",
                    marginLeft: '20px',
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <button
                    onClick={() => this.handleDeleteService(service.id, true)}
                    data-test-id="handleClear"
                    style={stepStyle.clearDivactive}
                  >
                    <ClearIcon />
                  </button>
                </div>
              </div>
              <Grid item xs={12}>
                {this.state.ownServiceInputErrors[`service_${service.id}`] && (
                  <Typography variant="body1" color="error">{this.state.ownServiceInputErrors[`service_${service.id}`]}</Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                {this.state.ownServiceInputErrors[`price_${service.id}`] && (
                  <Typography variant="body1" color="error">{this.state.ownServiceInputErrors[`price_${service.id}`]}</Typography>
                )}
              </Grid>
            </div>
          )
        })}
      </>
    )

  }

  serviceError = () => {
    return (
      <>
        {this.state.createOwServiceErrorMessage && <Grid item xs={12} >
          <Typography variant="subtitle2" color="error">{this.state.createOwServiceErrorMessage}</Typography>
        </Grid>}
      </>
    )
  }

  button = () => {
    return (
      <>
        <Grid style={stepStyle.buttonGrid as React.CSSProperties}>
          <Button
            style={stepStyle.backButton as React.CSSProperties}
            onClick={() => this.handleBack()}
            data-test-id="handleBack"
          >
            <div style={stepStyle.backBtnText}>
              <div style={stepStyle.KeyboardArrowLeftDiv}>
                <KeyboardArrowLeft style={stepStyle.backBtnIcon} />
              </div>
              <div style={stepStyle.backTextDiv}>
                <Typography variant="subtitle2" style={stepStyle.backText}>Back</Typography>
              </div>
            </div>
          </Button>

          <Grid>
            <Button
              style={stepStyle.nextButton as React.CSSProperties}
              onClick={this.handleNext}
              data-test-id="handleNext"
            >
              <div style={stepStyle.backTextMainDiv}>
                <div style={stepStyle.backTextDiv}>
                  <span>Save and Continue</span>
                </div>
                <div style={stepStyle.KeyboardArrowRightDiv}>
                  <KeyboardArrowRight />
                </div>
              </div>
            </Button>
          </Grid>
        </Grid>
        {this.state.firstPageError && (
          <p style={{ color: "red" }}>
            These Daycare price are required
          </p>
        )}
      </>
    );
  };

  saveDraftWarningData = () => {
    return (
      <>
        {((this.state.activeStep === 1 && this.state.saveDraftWarn) ||
          (this.state.activeStep === 2 && this.state.saveDraftWarn) ||
          (this.state.activeStep === 3 && this.state.saveDraftWarnSec)) &&
          <div style={stepStyle.saveDraftWarnes as React.CSSProperties}>
            <ReportProblemOutlinedIcon style={{ color: "rgb(195 129 0)" }} />
            <Typography style={stepStyle.saveDraftTextes as React.CSSProperties}>
              Draft will be saved for a period of 1 month and then deleted from the system
            </Typography>
          </div>}
      </>
    );
  }

  dayCareService = () => {
    return (
      <MainBox>
        <Box className="petSetupBox">
          <Box className="headBox">
            <Box className="heading">
              <Typography variant="body1" data-test-id="headingCallId">{configJSON.petHotelSetup}</Typography>
              <Button variant="text">{configJSON.draft}</Button>
            </Box>
            <Divider />
          </Box>
          <Box className="contentBox">
            <Box className="contentHead">
              <Typography variant="body1">{configJSON.price}</Typography>
              <Typography variant="body2">
                {configJSON.changeAnyTime}
              </Typography>
            </Box>
            <Box className="contentBody">
              <Divider />
              <Box className="priceCard">
                <Typography variant="body2">
                  {configJSON.spacesText}
                </Typography>
                <input
                  type="text"
                  placeholder=""
                  value={this.state.spaces}
                  onChange={this.updateSpace}
                  data-test-id="spacesCallId"
                  style={stepStyle.priceOfDogInput}
                />
              </Box>
              <Typography variant="body1">
                {configJSON.priceSettings}
              </Typography>
              {this.state.oneTimePrice.map((price, priceIndex) => (
                <Box className="priceCard">
                  <Typography variant="body2">
                    {configJSON.priceFor} {priceIndex + 1} {priceIndex === 0 ? configJSON.day : configJSON.days}{" "}
                  </Typography>
                  <input
                    type="text"
                    placeholder="£ 000.00"
                    value={price !== "" ? `£ ${price}` : ""}
                    onChange={(event) => this.updatePrice(event, priceIndex, "onetime")}
                    data-test-id={`oneTimeCallId_${priceIndex}`}
                    style={stepStyle.priceOfDogInput}
                  />
                </Box>
              ))}
              <br /><br /><br />
              <Typography variant="body1">{configJSON.weeklyPrice}</Typography>
              {this.state.weeklyPrice.map((price, weeklyPriceIndex) => (
                <Box className="priceCard">
                  <Typography variant="body2">
                    {configJSON.priceFor} {weeklyPriceIndex + 1} {weeklyPriceIndex === 0 ? configJSON.day : configJSON.days}{" "}
                  </Typography>
                  <input
                    style={stepStyle.priceOfDogInput}
                    placeholder="£ 000.00"
                    type="text"
                    value={price !== "" ? `£ ${price}` : ""}
                    data-test-id={`weeklyCallId_${weeklyPriceIndex}`}
                    onChange={(event) => this.updatePrice(event, weeklyPriceIndex, "weekly")}
                  />
                </Box>
              ))}
            </Box>
            {this.state.errorMsg ? <Box className="errorBox"><Typography className="errorMessage" data-test-id="errorMsgId">{this.state.errorMsg}</Typography></Box> : null}
            <Box className="btnBox">
              <Button className="backBtn" data-test-id="backButtonTestId" startIcon={<ArrowBackIosIcon />} onClick={this.handleBack}>
                {configJSON.back}
              </Button>
              <Button className="saveBtn" onClick={this.handleNext} endIcon={<ArrowForwardIosIcon />} data-test-id="saveButtonTestId">
                {configJSON.saveAndContinue}
              </Button>
            </Box>
          </Box>
        </Box>
      </MainBox>
    );
  };

  openStep = () => {
    let componentToRender = null;

    switch (this.state.activeStep) {
      case 1:
        componentToRender = this.firstStep();
        break;
      case 2:
        componentToRender = this.secondStep();
        break;
      case 3:
        componentToRender = this.thirdStep();
        break;
      case 4:
        componentToRender = this.forthStep();
        break;
      default:
    }
    return componentToRender;
  };

  renderCheckInPoup = (type: string) => {
    const { checkInFrom, checkOutFrom } = this.state;
    const selectedCheckInHour = checkInFrom ? parseInt(checkInFrom.split(':')[0]) : null;
    const selectedCheckOutHour = checkOutFrom ? parseInt(checkOutFrom.split(':')[0]) : null;

    return (
      <ClickAwayListener onClickAway={this.closePopUp}>
        <Box style={stepFourStyle.dropDown as React.CSSProperties}>
          <Box style={{ padding: "0.5rem" }}>
            {Array.from({ length: 24 }, (_, index) => {
              const hour = String(index).padStart(2, '0');
              let isDisabled = false;

              if (type === 'checkInUntil' && selectedCheckInHour !== null && index <= selectedCheckInHour) {
                isDisabled = true;
              }

              if (type === 'checkOutUntil' && selectedCheckOutHour !== null && index <= selectedCheckOutHour) {
                isDisabled = true;
              }

              return (
                <MenuItem
                  key={index}
                  onClick={() => !isDisabled && this.handleSelectClose(`${hour}:00`, type)}
                  data-test-id="handleSelectCloseOption"
                  style={stepFourStyle.dropDownText}
                  disabled={isDisabled}
                >
                  {`${hour}:00`}
                </MenuItem>
              );
            })}
          </Box>
        </Box>
      </ClickAwayListener>
    );
  };

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>

        <BussinessOwnerSideBar navigation={this.props.navigation} active={0} />
        <div style={stepStyle.roomsLayout as React.CSSProperties}>
          {this.openDilogSaveDraft()}
          {this.openModel()}
          {this.checkDayCareService() ?
            this.dayCareService()
            :
            <Grid style={stepStyle.mainGridIn}>
              <div style={stepStyle.mainDivIn}>
                <Grid data-test-id="newID">
                  <Grid>
                    <Grid>
                      <div
                        style={stepStyle.thirdStepTextDiv as React.CSSProperties}
                      >
                        <p
                          style={
                            stepStyle.firstTextCompt as React.CSSProperties
                          }
                        >
                          2. Let's Set Up Your Place
                        </p>
                        <span
                          style={stepStyle.thirdStepText as React.CSSProperties}
                          onClick={this.saveDraftModelOpen}
                          data-test-id="saveDraftModelOpen"
                        >
                          Save Draft
                        </span>
                      </div>
                    </Grid>
                  </Grid>
                  <div style={stepStyle.blueBorderBottom}>
                  </div>
                  {this.saveDraftWarningData()}
                  {this.openStep()}
                  {this.button()}
                </Grid>
              </div>
            </Grid>
          }

          <Grid item xs={12} style={{ marginTop: '10%' }}>
            <Footer footerNavigationData={this.state.socialLinksData} boAndPOLinks={this.state.poBOLinksData} />

          </Grid>
        </div>
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start

const styles = {
  buttonBox: {
    width: "10%",
    marginLeft: '20px',
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  inputDivBox: {
    width: "20%"
  },
  radioButton: {
    borderRadius: '8px',
    width: '100%',
    height: '33px',
  },
  radioGroup: {
    display: "flex !important",
    flexDirection: "row" as "row"
  },
  inputDiv: {
    width: "45%"
  },
  mainbox: {
    display: "flex",
    marginTop: "15px"
  },
  button: {
    display: "flex",
  },
  button1: {
    display: "flex",
    justifyContent: "right"
  },
  typBox: {
    display: "flex",
    paddingLeft: "70px"
  },
  radioBtn: {
    borderRadius: '8px',
    width: '100%',
    height: '33px',
  },
  headingWidth1: {
    width: "20%"
  },
  headingWidth2: {
    width: "45%"
  },
  headingWidth3: {
    width: "20%"
  },
  headingWidth4: {
    width: "7%"
  },
  radioStyle: {
    flexDirection: "row" as "row"
  }


}
const stepStyle = {
  roomsLayout: {
    marginLeft: "140px", // Adjust based on the width of your sidebar
    flexGrow: 1,
    backgroundColor: theme.palette.primary.main,
    overflowY: "auto", // Allow main reservationContent area to scroll
  },
  thirdStepTextDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  thirdStepText: {
    textDecoration: "underline",
    fontSize: "18px",
    fontWeight: 700,
    color: "#54A7C9",
    fontFamily: "inter",
    cursor: "pointer",
  },
  lineProgress: { width: "100%", color: "#B2E4F8", backgroundColor: "#B2E4F8" },
  blueBorderBottom: {
    backgroundColor: "#B2E4F8",
    padding: "2px 0",
    margin: "10px 0",
  },
  saveDraftWarnes: { display: "flex", alignItems: "center", height: "50px", backgroundColor: "#FEF3C7", paddingLeft: "10px" },
  saveDraftTextes: {
    fontFamily: "Inter",
    paddingLeft: "10px",
    fontSize: "16px",
    fontWeight: 400,
  },
  roomDetailsPetDiv: { marginTop: "3%", display: "flex", justifyContent: "flex-end" },
  radioButoonText: {

    fontSize: "16px",
    fontWeight: 700,
    color: "#334155",

  },
  menuItems: {
    marginTop: "3.4rem",
    "&:hover": {
      backgroundColor: "red"
    },
  },

  dilogDiv: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "1% 0px 3%",
  },
  servicesDiv: {
    border: "1px solid #F15DA5",
    borderRadius: "8px",
    width: "98%",
    display: "flex",
    alignItems: "center",
    margin: "10px 0px 10px 20px",
    paddingLeft: "10px",
    height: "41px",
    color: "var(--Neutrals-Cool-gray-700, #334155)",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px",
  },

  servicesInput: {
    border: "1px solid #F15DA5",
    borderRadius: "8px",
    width: "96.5%",
    display: "flex",
    alignItems: "center",
    margin: "10px 10px 10px 0px",
    paddingLeft: "10px",
    height: "41px",
    color: "var(--Neutrals-Cool-gray-700, #334155)",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    outline: "none",
  },

  activeInput: {
    border: "1px solid #B2E4F8",
    borderRadius: "8px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    margin: "10px 10px 10px 0px",
    paddingLeft: "10px",
    height: "41px",
    color: "var(--Neutrals-Cool-gray-700, #334155)",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    outline: "none",
  },

  thirdStepPriceInput: {
    border: "1px solid #F15DA5",
    borderRadius: "8px",
    margin: "10px 0px",
    paddingLeft: "10px",
    height: "41px",
    fontSize: "14px",
    fontWeight: 700,
    color: "#1E293B",
    width: "100%",
    marginLeft: "20px",
    outline: "none",

  },

  activeThirdStepPriceInput: {
    border: "1px solid #B2E4F8",
    borderRadius: "8px",
    margin: "10px 0px",
    paddingLeft: "10px",
    height: "41px",
    fontSize: "14px",
    fontWeight: 700,
    color: "#1E293B",
    width: "100%",
    marginLeft: "20px",
    outline: "none",

  },

  clearDiv: {
    width: "45px",
    border: "1px solid #F15DA5",
    borderRadius: "8px",
    margin: "10px 0px 10px 10px",
    height: "40px",
    fontSize: "14px",
    fontWeight: 700,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#1E293B",
    cursor: "pointer",
  },

  clearDivactive: {
    width: "45px",
    border: "1px solid #B2E4F8",
    borderRadius: "8px",
    margin: "10px 0px 10px 10px",
    height: "40px",
    fontSize: "14px",
    fontWeight: 700,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#1E293B",
    cursor: "pointer",
  },

  removeText: {
    fontSize: "16px",
    fontWeight: 400,
    textAlign: "center",
    margin: "31px 0px",
    color: "#334155",
  },
  removeHeadText: {
    fontSize: "24px",
    fontWeight: 700,
    textAlign: "center",
    margin: "20px 0px",
    color: "#334155",
  },
  remmoveRoomBtn: {
    height: "56px",
    borderRadius: "8px",
    border: "1px solid #64748B",
    width: "47%",
    textTransform: "none",
    fontSize: "16px",
    fontWeight: 700,
    color: "#EA0C78",
  },
  clodeModelBtn: {
    backgroundColor: "#EA0C78",
    height: "56px",
    borderRadius: "8px",
    width: "47%",
    textTransform: "none",
    fontSize: "16px",
    fontWeight: 700,
    color: "#fff",
  },
  modelButtonDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center" as const,
  },
  priceOfDogInput: {
    width: "19%",
    height: "41px",
    borderRadius: "8px",
    border: "1px solid #B2E4F8",
    backgroundColor: "#CCEDFA",
    fontSize: "16px",
    fontWeight: 400,
    textAlign: "right" as const,
    padding: "0 15px",
  },
  addroomText: {
    color: "#326478",
    fontSize: "20px",
    fontWeight: 700,
    margin: "0px 5px",
  },
  slectedPriceInputDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "2% 0px",
  },
  slectedPriceInput: {
    border: "1px solid #B2E4F8",
    width: "75%",
    height: "40px",
    borderRadius: "8px",
    fontSize: "14px",
    fontWeight: 700,
    fontFamily: "inter",
  },
  inputDiv: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    marginTop: "2%",
  },
  divOfInput: {
    width: "30%",
    height: "45px",
    backgroundColor: "#E0F4FC",
    borderRadius: "8px 0px 0px 8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px",
    color: "var(--Neutrals-Cool-gray-700, #334155)",
  },
  divOfInputText: {
    color: "var(--Neutrals-Cool-gray-500, #64748B)",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
  },
  expandMoreIcon: {
    color: "#64748B",
    height: "24px",
    width: "24px",
  },
  expandMoreIconRight: {
    color: "#64748B",
    height: "24px",
    width: "24px",
    marginRight: "8px"
  },
  additionalServiceText: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    color: "var(--Neutrals-Cool-gray-500, #64788)"
  },
  selectTime: {
    width: "78%",
    height: "44px",
    padding: "0px 5%",
    border: "1px solid #B2E4F8",
    borderRadius: "0px 8px 8px 0px",
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    position: 'relative' as const
  },
  secondFormPriceDiv: {
    fontSize: "16px",
    fontWeight: 700,
    color: "#334155",
    backgroundColor: "#B2E4F8",
    width: "130px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "8px",
  },
  handleRemoveDiv: {
    display: "flex",
    alignItems: "center",
    color: "rgb(255 108 180)",
    width: "20%",
    justifyContent: "space-evenly",
    marginRight: "7px",

  },
  properiesRemoveDiv: {
    display: "flex",
    alignItems: "center",
    color: "rgb(255 108 180)",
    width: "20%",
    justifyContent: "space-evenly",
    marginRight: "7px",

  },
  errorServicePrice: {
    color: "red",
    fontSize: "14px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
  },
  handleonClickAddDiv: {
    cursor: "pointer",
    color: "#EA0C78",
  },
  handleonClickRemoveDiv: {
    display: "flex",
    alignItems: "center",

    cursor: "pointer",
  },
  propertiesDiv: {
    display: "flex",
    alignItems: "center",

    cursor: "pointer",
  },
  secondStepInputDiv: {
    border: "1px solid #B2E4F8",
    height: "40px",
    fontSize: "14px",
    fontWeight: "400",
    color: "#1E293B",
    fontFamily: "inter",
    borderRadius: "8px",
    width: "95.5%",
    marginTop: "5px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 2%",
  },
  secondStepInput: {
    border: "1px solid #B2E4F8",
    height: "40px",
    fontSize: "14px",
    fontWeight: "400",
    color: "#1E293B",
    fontFamily: "inter",
    borderRadius: "8px",
    width: "99%",
    marginTop: "5px",
    paddingLeft: "7px",

  },
  firstStepsLable: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#64748B",
    marginLeft: "3%",
  },
  firstStepForm: {
    paddingBottom: "5%",
    borderBottom: "1px solid #B2E4F8",
  },
  firstStepCheckBoxDiv: {
    width: "50%",
    display: "flex",
    alignItems: "center",

  },
  addRoomDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "2% 0px 6%",
    borderBottom: "1px solid #B2E4F8",
  },
  threeStepSelectLable: {
    fontWeight: 700,
    color: "#334155",
    fontSize: "14px",

  },
  threeStepSelectLable2: {
    fontWeight: 700,
    color: "#334155",
    marginTop: "20px",
    fontSize: "14px",

  },

  firstStepText: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#EA0C78",
    textDecoration: "underline",
    cursor: "pointer",
  },

  firstStepTextBlack: {
    color: "#334155",
    fontSize: "14px",
    fontWeight: 700,
    textDecoration: "underline",
    cursor: "pointer",
  },
  threeStepInputText: {
    fontWeight: 700,
    color: "#64748B",
    fontSize: "12px",
    marginLeft: "12px"

  },

  threeStepInputTextLeft: {
    fontWeight: 700,
    color: "#64748B",
    fontSize: "12px",
    marginLeft: "3px"
  },
  countrySelect: {
    width: "98%",
    paddingLeft: "10px",
    height: "49px",
    alignItems: "center",
    marginTop: "10px",
    border: "1px solid #B2E4F8",
    backgroundColor: " #B2E4F8",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "space-between",

  },
  fontHadingText: {
    fontFamily: "Inter",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "32px",
    letterspacing: "-0.12px"

  },

  conditionBg: {
    backgroundColor: "#C1E9F9",
    borderBottom: "1px solid #E0F4FC",
    padding: "10px",
    cursor: "pointer",
  },
  transparentBg: {
    backgroundColor: "transparent",
    borderBottom: "1px solid #E0F4FC",
    padding: "10px",
    cursor: "pointer",
  },
  nextButton: {
    minWidth: "210px",
    height: "44px",
    borderRadius: "8px",
    padding: "10px 16px 10px 16px",
    backgroundColor: "#EA0C78",
    margin: "10px 0px 0px 0px",
    fontWeight: "700",
    color: "#fff",
    textTransform: "none",
    paddingLeft: "30px"
  },
  backButton: {
    width: "76px",
    height: "26px",
    backgroundColor: "#fff",
    padding: "10px 16px 10px 16px",
    color: "#64748B",
    fontWeight: "700",
    textTransform: "none",
  },
  buttonGrid: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    flexWrap: "wrap",
    margin: "40px 0px 30px 0px",
  },
  firstTextCompt: {
    color: "var(--Neutrals-Cool-gray-700, #334155)",

    fontFamily: "Inter",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "32px",
    letterSpacing: "-0.12px"
  },
  mainContainerSecDiv: {
    marginTop: "5px",
    cursor: "pointer"
  },
  mainGridIn: {
    display: "flex",
    justifyContent: "center",
    padding: "0px 50px 80px",
    backgroundColor: "rgb(224, 244, 252)",
    height: "auto",
    minHeigth: "81vh",
  },
  mainDivIn: {
    width: "55%",
    padding: "3% 6%",
    backgroundColor: "#fff",
    borderBottomRightRadius: "60px",
  },
  secondStepMainDIv: {
    margin: "50px 0px 30px 0px",
  },
  PricesTextSpan: {

    color: "#334155",
  },
  firstStepTextSpan: {
    color: "var(--Neutrals-Cool-gray-700, #334155)",

    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "22px"
  },
  secTextarea: {
    width: "99%",
    resize: "none",
    height: "161px",
    borderRadius: "5px",
    border: "1px solid #B2E4F8",
    fontFamily: "inter",
    fontSize: "14px",
    fontWeight: 400,
    marginTop: "5px",
    padding: "5px 7px",
  },
  backBtnText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  KeyboardArrowLeftDiv: {
    height: "24px",
    width: "24px",
    marginRight: 10
  },
  backTextDiv: {
    height: "24px",
  },
  backTextMainDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  KeyboardArrowRightDiv: {
    marginLeft: 10,
    height: "24px",
    width: "24px",
  },
  backText: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.black
  },
  backBtnIcon: {
    color: theme.palette.common.black

  }
};

const stepFourStyle = {
  countrySpan: { color: "#64748B", fontSize: "16px", fontWeight: 400, fontFamily: "inter", textTransform: "capitalize" },
  numberSelect: {
    cursor: "pointer",
    paddingLeft: "10px",
    width: "100%",
    height: "39px",
    border: "1px solid #B2E4F8",
    borderRadius: "8px",
    backgroundColor: "#E0F4FC",
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
  },
  saveDraftsBtns: {
    fontWeight: 700,
    fontSize: "16px",
    fontFamily: "inter",
    color: "#fff",
    backgroundColor: "#EA0C78",
    textTransform: "none",
    width: "192px",
    borderRadius: "8px",
    height: "59px",
  },

  modelBtnDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  modelBodyTexts: {
    fontSize: "14px",
    textAlign: "center",
    color: "#475569",
    fontWeight: 700,
  },
  modelHeadingTexts: {
    fontSize: "24px",
    textAlign: "center",
    color: "#224350",
    fontWeight: 700,
  },
  discartBtns: {
    fontSize: "16px",
    color: "#EA0C78",
    fontWeight: 700,
    fontFamily: "inter",
    border: "1px solid #EA0C78",
    textTransform: "none",
    width: "192px",
    borderRadius: "8px",
    height: "59px",
  },
  dropDown: {
    position: 'absolute',
    borderRadius: '8px',
    width: '100%',
    background: "#ffffff",
    height: '250px',
    left: 0,
    overflowY: "auto",
    top: 50,
    border: "1px solid  #E2E8F0",
    boxShadow: "0px 25px 50px 0px rgba(0, 0, 0, 0.09), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)",
    zIndex: 99 as const
  },
  dropDownText: {
    fontSize: '16px',
    borderBottom: "1px solid #E0F4FC",
    padding: "10px",
    cursor: "pointer",
  }
};

const MainBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  background: "#E0F4FC",
  "& .petSetupBox": {
    borderBottomRightRadius: "60px",
    background: "white",
    padding: "0px 115px",
    width: "900px",
    marginBottom: "100px"
  },
  "& .headBox": {
    padding: "45px 0px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    "& .MuiDivider-root": {
      height: "4px",
      background: "#B2E4F8",
    },
  },
  "& .heading": {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    "& .MuiTypography-body1": {
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "32px",
      fontFamily: "Inter",
      color: "#334155",
    },
    "& .MuiButton-root": {
      color: "#54A7C9",
      fontFamily: "Inter",
      fontSize: "18px",
      fontWeight: 700,
      lineHeight: "26px",
      textTransform: "none",
      textDecoration: "underline",
    },
  },
  "& .contentBox": {
    display: "flex",
    flexDirection: "column",
    "& .MuiDivider-root": {
      height: "1px",
      background: "#B2E4F8",
    },
  },
  "& .contentHead": {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
    padding: "20px 0px",
    "& .MuiTypography-body2": {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "22px",
      fontFamily: "Inter",
      color: "#334155",
    },
    "& .MuiTypography-body1": {
      fontSize: "30px",
      lineHeight: "40px",
      fontFamily: "Inter",
      color: "#334155",
      fontWeight: 700,
    },
  },
  "& .contentBody": {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    "& .MuiTypography-body1": {
      color: "#334155",
      fontSize: "14px",
      fontFamily: "Inter",
      lineHeight: "22px",
      fontWeight: 700,
    },
    "& .MuiTypography-body2": {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "22px",
      fontFamily: "Inter",
      color: "#334155",
    },
  },
  "& .priceCard": {
    display: "flex",
    paddingLeft: "20px",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid #B2E4F8",
    borderRadius: "8px",
  },
  "& .priceBox": {
    backgroundColor: "#CCEDFA",
    borderRadius: "8px",
    padding: "10px 20px",
    border: "1px solid #B2E4F8",
    color: "#224350 !important",
    width: "125px",
    textAlign: "right",
  },
  "& .btnBox": {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "30px 0px 45px 0px",
    "& .MuiButton-root": {
      fontFamily: "Inter",
      fontSize: "16px",
      fontWeight: 700,
      borderRadius: "8px",
      lineHeight: "22px",
      textTransform: "none",
      padding: "10px 16px",
    },
  },
  "& .saveBtn": {
    color: "white",
    background: "#EA0C78",
  },
  "& .backBtn": {
    color: "#0F172A"
  },
  "& .errorMessage": {
    color: "red"
  },
  "& .errorBox": {
    paddingTop: "10px",
    width: "100%"
  }
});

// Customizable Area End
