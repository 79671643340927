Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

// Customizable Area Start


exports.emailType = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
exports.countries = [
  { label: 'United Kingdom', phone: '(+44)' },
  { code: 'AF', label: 'Afghanistan', phone: '(+93)' },
  { code: 'AL', label: 'Albania', phone: '(+355)' },
  { code: 'AX', label: 'Alland Islands', phone: '(+358)' },
  { code: 'AD', label: 'Andorra', phone: '(+376)' },
  { ce: 'AI', label: 'Anguilla', phone: '(+1-264)' },
  { code: 'AG', label: 'Antigua and Barbuda', phone: '(+1-268)' },
  { code: 'AR', label: 'Argentina', phone: '(+54)' },
  { label: 'Armenia', phone: '(+374)' },
  { coe: 'AW', label: 'Aruba', phone: '(+297)' },
  { label: 'Australia', phone: '(+61)' },
  { code: 'AT', label: 'Austria', phone: '(+43)' },
  { label: 'Azerbaijan', phone: '(+994)' },
  { code: 'BS', label: 'Bahamas', phone: '(+1-242)' },
  { label: 'Bermuda', phone: '(+1-441)' },
  { o: 'BT', label: 'Bhutan', phone: '(+975)' },
  { ce: 'BO', label: 'Bolivia', phone: '(+591)' },
  { code: 'BA', label: 'Bosnia and Herzegovina', phone: '(+387)' },
  { label: 'Bouvet Island', phone: '(+47)' },
  { label: 'Brazil', phone: '(+55)' },
  { label: 'British Indian Ocean Territory', phone: '(+246)' },
  { code: 'BN', label: 'Brunei Darussalam', phone: '(+673)' },
  { label: 'Burundi', phone: '(+257)', co: 'hdfj' },
  { coe: 'CM', label: 'Cameroon', phone: '(+237)' },
  { code: 'CV', label: 'Cape Verde', phone: '(+238)' },
  { code: 'CN', label: 'China', phone: '(+86)' },
  { ce: 'CX', label: 'Christmas Island', phone: '(+61)' },
  { label: 'Colombia', phone: '(+57)' },
  { code: 'KM', label: 'Comoros', phone: '(+269)' },
  { code: 'CK', label: 'Cook Islands', phone: '(+682)' },
  { code: 'CR', label: 'Costa Rica', phone: '(+506)' },
  { label: 'Cuba', phone: '(+53)' },
  { code: 'CW', label: 'Curacao', phone: '(+599)' },
  { label: 'Cyprus', phone: '(+357)' },
  { code: 'CZ', label: 'Czech Republic', phone: '(+420)' },
  { ode: 'FO', label: 'Faroe Islands', phone: '(+298)' },
  { code: 'FR', label: 'France', phone: '(+33)' },
  { label: 'French Guiana', phone: '(+594)' },
  { ce: 'GM', label: 'Gambia', phone: '(+220)' },
  { label: 'Georgia', phone: '(+995)' },
  { ode: 'DE', label: 'Germany', phone: '(+49)' },
  { code: 'GH', label: 'Ghana', phone: '(+233)' },
  { label: 'Grenada', phone: '(+1-473)' },
  { label: 'Guadeloupe', phone: '(+590)' },
  { code: 'GG', label: 'Guernsey', phone: '(+44)' },
  { code: 'GN', label: 'Guinea', phone: '(+224)' },
  { code: 'HU', label: 'Hungary', phone: '(+36)' },
  { code: 'IN', label: 'India', phone: '(+91)' },
  { label: 'Iraq', phone: '(+964)' },
  { code: 'IE', label: 'Ireland', phone: '(+353)' },
  { label: 'Isle of Man', phone: '(+44)' },
  { code: 'IL', label: 'Israel', phone: '(+972)' },
  { ode: 'KR', label: 'Korea, Republic of', phone: '(+82)' },
  { code: 'KW', label: 'Kuwait', phone: '(+965)' },
  {
    code: 'LA',
    label: "Lao People's Democratic Republic",
    phone: '(+856)'
  },
  { label: 'Madagascar', phone: '(+261)' },
  { code: 'NG', label: 'Nigeria', phone: '(+234)' },
  { code: 'NU', label: 'Niue', phone: '(+683)' },
  { code: 'Rs', label: 'Russian Federation', phone: '(+7)' },
  { cde: 'RW', label: 'Rwanda', phone: '(+250)' },
  { label: 'Saint Barthelemy', phone: '(+590)' },
  { code: 'MF', label: 'Saint Martin (French part)', phone: '(+590)' },
  { label: 'Serbia', phone: '(+381)', code: 'SB' },
  { label: 'Seychelles', phone: '(+248)' },
  { code: 'SB', label: 'Solomon Islands', phone: '(+677)' },
  { code: 'AE', label: 'United Arab Emirates', phone: '(+971)' },
  { code: 'US', label: 'United States', phone: '(+1)' },
  { odc: 'YE', label: 'Yemen', phone: '(+967)' },
  { cd: 'ZM', label: 'Zambia', phone: '(+260)' },
  { ce: 'ZM', label: 'Zambia', phone: '(+260)' },
  { label: 'Zimbabwe', phone: '(+263)' }
];

exports.MesErrFormFirst = {
  businessName:"Please enter business name.",
  licenseNumber: "Please enter license number.",
  streetAddressFirst: "Please enter the street address.",
  city: "Please enter your city.",
  postcode: "Please enter your postcode.",
};
exports.MesErrFormSecond = {
  phoneNumber:"Please enter your phone number.",
  email: "Please enter your email.",
  representativeFirstName:"Please enter representative first name.",
  representativeSurname: "Please enter representative sur name.",
  representativeAddressOne:"Please enter representative address.",
  representativeCity: "Please enter representative city.",
  representativePostCode: "Please enter representative ostcode.",
};
exports.MesErrFormThird = {
  titleText:"Please enter title.",
  descriptionText: "Please enter description.",
};
exports.placeHolderEmail = "Email";
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.labelEmail = "Email";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch = "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.hintCountryCode = "Select Country";
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";

exports.emailRegex=/^\s*[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\s*$/;
exports.nameRegex=/^\s*[a-zA-Z]+(?:\s[a-zA-Z]+)*\s*$/;
exports.numerRegex= /^\s*\d+\s*$/;
exports.pdfRegex = /\.pdf$/i;
exports. urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

exports.stripeAccoutnApi="bx_block_stripe_integration/stripe_connected_accounts"
exports.HotelListEndPoint="bx_block_hotel/all_properties"
exports.getReviewsEndPoint="bx_block_hotel_review/list_hotel_reviews?hotel_id="
exports.getCancelationPolicyEndPoint="bx_block_cancellation/cancellation_policies?hotel_id="
exports.standardPolicyEndPoint="bx_block_settings/standard_cancellations"
exports.hotelEndPoint="bx_block_custom_form/hotels";
exports.cancellationPolicyEndPoint='bx_block_settings/cancellation_policies'



exports.propertyText={
  property:"Property Name*",
  edit:"Edit",
  save:"Save",
  licience:"License Number*",
  contact:'Contact Details*',
  description:'Description*',
  days:'Block off days',
  address:"Address",
  select:'Select dates',
  clear:'Clear dates',
  reviews:"Pet owner reviews",
  cancellation:"Choose cancellation policy for this business*",
  standard:"Standard cancellation policy",
  anyTime:"You can change it anytime.",
  dropDown:"Additional Conditions and Questions of the hotel",
  conditions:"Additional conditions of the hotel",
  question1:"1. Additional question",
  question2:"2. Additional question",
  question3:"3. Additional question"

}

exports.createErrorMessage="Please enter the create own service name";
exports.withoutpriceErrorMessage="Please enter the price"
exports.withoutpriceErrorMessage="Please enter the room price"
exports.url = "https://api.maptiler.com/maps/streets-v2/256/{z}/{x}/{y}.png?key=SX5K85uAbJBHcmznSrkR";
exports.googleUrl = "https://nominatim.openstreetmap.org/search?postalcode=";
exports.params = "&format=json";
exports.createTypeErrorMessage = "Type is not defined for selected service";
exports.customServiceEndpoint = "bx_block_custom_form/hotels/create_your_own_services";
exports.saveAndContinue = "Save and Continue";
exports.back = "Back";
exports.petHotelSetup = "3. Pet Hotel Setup";
exports.draft = "Save Draft";
exports.changeAnyTime = "You can change it anytime.";
exports.price = "Prices and Discounts";
exports.priceSettings = "Set the price for daycare based on the number of days:";
exports.weeklyPrice = "Set the Weekly price for daycare based on the number of days:";
exports.priceFor = "Price for";
exports.day= "day";
exports.days= "days";
exports.dayCareServiceEndpoint = "bx_block_custom_form/day_care_services";
exports.formData = "multipart/form-data";
exports.addRoomType = "Add Room Type";
exports.countyText = "Country*";
exports.county = "County*";
exports.country = "Country";
exports.county = "County*";
exports.mccApiEndPoint = "bx_block_stripe_integration/mcc";
exports.regex = /^\d*(\.\d{0,2})?$/;
exports.countyRegex=/^[a-zA-Z\s]*$/;
exports.numberRegex=/^\d*$/;
exports.spacesText="The number of spaces";
exports.frequency="FREQUENCY";
exports.serviceType="SERVICE TYPE";
exports.doggyDaycare="Doggy Daycare";
exports.deleteMethod = "DELETE";
exports.deleteServiceAPI = "bx_block_custom_form/hotels/destroy_services"
// Customizable Area End

